import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
import UserLink from "discourse/components/user-link";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ReviewableCreatedByName = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="names">
    <span class="username">
      {{#if @user}}
        <UserLink @user={{@user}}>{{@user.username}}</UserLink>
        {{#if @user.silenced}}
          {{icon "ban" title="user.silenced_tooltip"}}
        {{/if}}
      {{else}}
        {{i18n "review.deleted_user"}}
      {{/if}}
    </span>
    <PluginOutlet
      @name="after-reviewable-post-user"
      @connectorTagName="div"
      @outletArgs={{hash user=@user}}
    />
  </div>

*/
{
  "id": "8ZY6CgB9",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"names\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"username\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[32,0],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,[30,1,[\"username\"]]]],[]]]]],[1,\"\\n\"],[41,[30,1,[\"silenced\"]],[[[1,\"          \"],[1,[28,[32,1],[\"ban\"],[[\"title\"],[\"user.silenced_tooltip\"]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"        \"],[1,[28,[32,2],[\"review.deleted_user\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[8,[32,3],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"after-reviewable-post-user\",\"div\",[28,[32,4],null,[[\"user\"],[[30,1]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/reviewable-created-by-name.js",
  "scope": () => [UserLink, icon, i18n, PluginOutlet, hash],
  "isStrictMode": true
}), templateOnly());
export default ReviewableCreatedByName;