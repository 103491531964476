import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { waitForPromise } from "@ember/test-waiters";
import { isDocumentRTL } from "discourse/lib/text-direction";
import { prefersReducedMotion } from "discourse/lib/utilities";
import { isTesting } from "discourse-common/config/environment";
import discourseLater from "discourse-common/lib/later";
import closeOnClickOutside from "../../modifiers/close-on-click-outside";
import SidebarHamburgerDropdown from "../sidebar/hamburger-dropdown";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const CLOSE_ON_CLICK_SELECTORS = "a[href], .sidebar-section-header-button, .sidebar-section-link-button, .sidebar-section-link";
export default class HamburgerDropdownWrapper extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  toggleNavigation() {
    this.args.toggleNavigationMenu(this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu ? "hamburger" : null);
  }
  static #_4 = (() => dt7948.n(this.prototype, "toggleNavigation", [action]))();
  click(e1) {
    if (e1.target.closest(CLOSE_ON_CLICK_SELECTORS)) {
      this.toggleNavigation();
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "click", [action]))();
  clickOutside(e1) {
    if (e1.target.classList.contains("header-cloak") && !prefersReducedMotion()) {
      const panel1 = document.querySelector(".menu-panel");
      const headerCloak1 = document.querySelector(".header-cloak");
      const finishPosition1 = isDocumentRTL() ? "340px" : "-340px";
      const panelAnimatePromise1 = panel1.animate([{
        transform: `translate3d(${finishPosition1}, 0, 0)`
      }], {
        duration: isTesting() ? 0 : 200,
        fill: "forwards",
        easing: "ease-in"
      }).finished.then(() => {
        if (isTesting()) {
          this.toggleNavigation();
        } else {
          discourseLater(() => this.toggleNavigation());
        }
      });
      const cloakAnimatePromise1 = headerCloak1.animate([{
        opacity: 0
      }], {
        duration: isTesting() ? 0 : 200,
        fill: "forwards",
        easing: "ease-in"
      }).finished;
      waitForPromise(panelAnimatePromise1);
      waitForPromise(cloakAnimatePromise1);
    } else {
      this.toggleNavigation();
    }
  }
  static #_6 = (() => dt7948.n(this.prototype, "clickOutside", [action]))();
  get forceMainSidebarPanel() {
    // NOTE: In this scenario, we are forcing the sidebar on admin users,
    // so we need to still show the hamburger menu and always show the main
    // panel in that menu.
    if (this.args.sidebarEnabled && this.sidebarState.adminSidebarAllowedWithLegacyNavigationMenu) {
      return true;
    }
    return false;
  }
  static #_7 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        class="hamburger-dropdown-wrapper"
        {{! template-lint-disable no-invalid-interactive }}
        {{on "click" this.click}}
        {{! we don't want to close the hamburger dropdown when clicking on the hamburger dropdown itself
          so we use the secondaryTargetSelector to prevent that }}
        {{closeOnClickOutside
          this.clickOutside
          (hash
            targetSelector=".hamburger-panel"
            secondaryTargetSelector=".hamburger-dropdown"
          )
        }}
        ...attributes
      >
        <SidebarHamburgerDropdown
          @forceMainSidebarPanel={{this.forceMainSidebarPanel}}
        />
      </div>
    
  */
  {
    "id": "a4v61K2j",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"hamburger-dropdown-wrapper\"],[17,1],[4,[32,0],[\"click\",[30,0,[\"click\"]]],null],[4,[32,1],[[30,0,[\"clickOutside\"]],[28,[32,2],null,[[\"targetSelector\",\"secondaryTargetSelector\"],[\".hamburger-panel\",\".hamburger-dropdown\"]]]],null],[12],[1,\"\\n      \"],[8,[32,3],null,[[\"@forceMainSidebarPanel\"],[[30,0,[\"forceMainSidebarPanel\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/hamburger-dropdown-wrapper.js",
    "scope": () => [on, closeOnClickOutside, hash, SidebarHamburgerDropdown],
    "isStrictMode": true
  }), this))();
}