import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKControlMenuDivider = setComponentTemplate(createTemplateFactory(
/*
  
  <@divider class="form-kit__control-menu-divider" />

*/
{
  "id": "e3bbHlKa",
  "block": "[[[1,\"\\n  \"],[8,[30,1],[[24,0,\"form-kit__control-menu-divider\"]],null,null],[1,\"\\n\"]],[\"@divider\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/menu/divider.js",
  "isStrictMode": true
}), templateOnly());
export default FKControlMenuDivider;