import { tracked } from "@glimmer/tracking";
import { registerDestructor } from "@ember/destroyable";
import Service, { service } from "@ember/service";
import { TrackedSet } from "@ember-compat/tracked-built-ins";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import { currentPanelKey, customPanels as panels } from "discourse/lib/sidebar/custom-sections";
import { getCollapsedSidebarSectionKey } from "discourse/lib/sidebar/helpers";
import { COMBINED_MODE, MAIN_PANEL, SEPARATED_MODE } from "discourse/lib/sidebar/panels";
import escapeRegExp from "discourse-common/utils/escape-regexp";
const SidebarState = dt7948.c(class SidebarState extends Service {
  static #_ = (() => dt7948.g(this.prototype, "keyValueStore", [service]))();
  #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "currentPanelKey", [tracked], function () {
    return currentPanelKey;
  }))();
  #currentPanelKey = (() => (dt7948.i(this, "currentPanelKey"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "mode", [tracked], function () {
    return COMBINED_MODE;
  }))();
  #mode = (() => (dt7948.i(this, "mode"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "displaySwitchPanelButtons", [tracked], function () {
    return false;
  }))();
  #displaySwitchPanelButtons = (() => (dt7948.i(this, "displaySwitchPanelButtons"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "filter", [tracked], function () {
    return "";
  }))();
  #filter = (() => (dt7948.i(this, "filter"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "isForcingAdminSidebar", [tracked], function () {
    return false;
  }))();
  #isForcingAdminSidebar = (() => (dt7948.i(this, "isForcingAdminSidebar"), void 0))();
  panels = (() => panels)();
  collapsedSections = (() => new TrackedSet())();
  previousState = {};
  #hiders = (() => new TrackedSet())();
  get sidebarHidden() {
    return this.#hiders.size > 0;
  }
  registerHider(ref) {
    this.#hiders.add(ref);
    registerDestructor(ref, () => {
      this.#hiders.delete(ref);
    });
  }
  setPanel(name) {
    if (this.currentPanelKey) {
      this.setPreviousState();
    }
    this.currentPanelKey = name;
    this.restorePreviousState();
  }
  get currentPanel() {
    return this.panels.find(panel => panel.key === this.currentPanelKey);
  }
  setSeparatedMode() {
    this.mode = SEPARATED_MODE;
    this.showSwitchPanelButtons();
  }
  setCombinedMode() {
    this.mode = COMBINED_MODE;
    this.currentPanelKey = MAIN_PANEL;
    this.hideSwitchPanelButtons();
  }
  showSwitchPanelButtons() {
    this.displaySwitchPanelButtons = true;
  }
  hideSwitchPanelButtons() {
    this.displaySwitchPanelButtons = false;
  }
  setPreviousState() {
    this.previousState[this.currentPanelKey] = {
      mode: this.mode,
      displaySwitchPanelButtons: this.displaySwitchPanelButtons
    };
  }
  collapseSection(sectionKey) {
    const collapsedSidebarSectionKey = getCollapsedSidebarSectionKey(sectionKey);
    this.keyValueStore.setItem(collapsedSidebarSectionKey, true);
    this.collapsedSections.add(collapsedSidebarSectionKey);
  }
  expandSection(sectionKey) {
    const collapsedSidebarSectionKey = getCollapsedSidebarSectionKey(sectionKey);
    this.keyValueStore.setItem(collapsedSidebarSectionKey, false);
    this.collapsedSections.delete(collapsedSidebarSectionKey);
  }
  isCurrentPanel(panel) {
    return this.currentPanel.key === panel;
  }
  restorePreviousState() {
    const state = this.previousState[this.currentPanelKey];
    if (!state) {
      return;
    }
    if (state.mode === SEPARATED_MODE) {
      this.setSeparatedMode();
    } else if (state.mode === COMBINED_MODE) {
      this.setCombinedMode();
    }
    if (state.displaySwitchPanelButtons) {
      this.showSwitchPanelButtons();
    } else {
      this.hideSwitchPanelButtons();
    }
  }
  get combinedMode() {
    return this.mode === COMBINED_MODE;
  }
  get showMainPanel() {
    return this.currentPanelKey === MAIN_PANEL;
  }
  get currentUserUsingAdminSidebar() {
    return this.currentUser?.use_admin_sidebar;
  }
  get adminSidebarAllowedWithLegacyNavigationMenu() {
    return this.currentUserUsingAdminSidebar && this.siteSettings.navigation_menu === "header dropdown";
  }
  get sanitizedFilter() {
    return escapeRegExp(this.filter.toLowerCase().trim());
  }
  clearFilter() {
    this.filter = "";
  }
}, [disableImplicitInjections]);
export default SidebarState;