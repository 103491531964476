import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DiscourseURL from "discourse/lib/url";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import I18n from "discourse-i18n";
import DMenu from "float-kit/components/d-menu";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
function entranceDate(dt1, showTime1) {
  const today1 = new Date();
  if (dt1.toDateString() === today1.toDateString()) {
    return moment(dt1).format(I18n.t("dates.time"));
  }
  if (dt1.getYear() === today1.getYear()) {
    // No year
    return moment(dt1).format(showTime1 ? I18n.t("dates.long_date_without_year_with_linebreak") : I18n.t("dates.long_no_year_no_time"));
  }
  return moment(dt1).format(showTime1 ? I18n.t("dates.long_date_with_year_with_linebreak") : I18n.t("dates.long_date_with_year_without_time"));
}
export default class TopicEntrance extends Component {
  static #_ = (() => dt7948.g(this.prototype, "historyStore", [service]))();
  #historyStore = (() => (dt7948.i(this, "historyStore"), void 0))();
  get createdDate() {
    return new Date(this.args.topic.created_at);
  }
  get bumpedDate() {
    return new Date(this.args.topic.bumped_at);
  }
  get showTime() {
    return this.bumpedDate.getTime() - this.createdDate.getTime() < 1000 * 60 * 60 * 24 * 2;
  }
  get topDate() {
    return entranceDate(this.createdDate, this.showTime);
  }
  get bottomDate() {
    return entranceDate(this.bumpedDate, this.showTime);
  }
  jumpTo(destination1) {
    this.historyStore.set("lastTopicIdViewed", this.args.topic.id);
    DiscourseURL.routeTo(destination1);
  }
  static #_2 = (() => dt7948.n(this.prototype, "jumpTo", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DMenu
        @title={{@title}}
        @ariaLabel={{@title}}
        @placement="center"
        @autofocus={{true}}
        @triggerClass={{@triggerClass}}
      >
        <:trigger>
          {{yield}}
        </:trigger>
  
        <:content>
          <div id="topic-entrance" class="--glimmer">
            <button
              {{on "click" (fn this.jumpTo @topic.url)}}
              aria-label={{i18n
                "topic_entrance.sr_jump_top_button"
                date=this.topDate
              }}
              title={{i18n "topic_entrance.jump_top_button_title"}}
              class="btn btn-default full jump-top"
            >
              {{icon "step-backward"}}
              {{htmlSafe this.topDate}}
            </button>
  
            <button
              {{on "click" (fn this.jumpTo @topic.lastPostUrl)}}
              aria-label={{i18n
                "topic_entrance.sr_jump_bottom_button"
                date=this.bottomDate
              }}
              title={{i18n "topic_entrance.jump_bottom_button_title"}}
              class="btn btn-default full jump-bottom"
            >
              {{htmlSafe this.bottomDate}}
              {{icon "step-forward"}}
            </button>
          </div>
        </:content>
      </DMenu>
    
  */
  {
    "id": "RtO/XxAz",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@title\",\"@ariaLabel\",\"@placement\",\"@autofocus\",\"@triggerClass\"],[[30,1],[30,1],\"center\",true,[30,2]]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[10,0],[14,1,\"topic-entrance\"],[14,0,\"--glimmer\"],[12],[1,\"\\n          \"],[11,\"button\"],[16,\"aria-label\",[28,[32,1],[\"topic_entrance.sr_jump_top_button\"],[[\"date\"],[[30,0,[\"topDate\"]]]]]],[16,\"title\",[28,[32,1],[\"topic_entrance.jump_top_button_title\"],null]],[24,0,\"btn btn-default full jump-top\"],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"jumpTo\"]],[30,3,[\"url\"]]],null]],null],[12],[1,\"\\n            \"],[1,[28,[32,4],[\"step-backward\"],null]],[1,\"\\n            \"],[1,[28,[32,5],[[30,0,[\"topDate\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[11,\"button\"],[16,\"aria-label\",[28,[32,1],[\"topic_entrance.sr_jump_bottom_button\"],[[\"date\"],[[30,0,[\"bottomDate\"]]]]]],[16,\"title\",[28,[32,1],[\"topic_entrance.jump_bottom_button_title\"],null]],[24,0,\"btn btn-default full jump-bottom\"],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"jumpTo\"]],[30,3,[\"lastPostUrl\"]]],null]],null],[12],[1,\"\\n            \"],[1,[28,[32,5],[[30,0,[\"bottomDate\"]]],null]],[1,\"\\n            \"],[1,[28,[32,4],[\"step-forward\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@title\",\"@triggerClass\",\"@topic\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/topic-entrance.js",
    "scope": () => [DMenu, i18n, on, fn, icon, htmlSafe],
    "isStrictMode": true
  }), this))();
}