import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKText = setComponentTemplate(createTemplateFactory(
/*
  
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>

*/
{
  "id": "ftVkxDau",
  "block": "[[[1,\"\\n  \"],[11,2],[24,0,\"form-kit-text\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/text.js",
  "isStrictMode": true
}), templateOnly());
export default FKText;