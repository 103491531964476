import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKSection = setComponentTemplate(createTemplateFactory(
/*
  
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>

*/
{
  "id": "wG10/z8Q",
  "block": "[[[1,\"\\n  \"],[11,0],[16,0,[28,[32,0],[\"form-kit__section\",[30,1]],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,\"h2\"],[14,0,\"form-kit__section-title\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,1],[14,0,\"form-kit__section-subtitle\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@title\",\"@subtitle\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/section.js",
  "scope": () => [concatClass],
  "isStrictMode": true
}), templateOnly());
export default FKSection;