import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DButton from "discourse/components/d-button";
import PluginOutlet from "discourse/components/plugin-outlet";
import PrivateMessageMap from "discourse/components/topic-map/private-message-map";
import TopicMapExpanded from "discourse/components/topic-map/topic-map-expanded";
import TopicMapSummary from "discourse/components/topic-map/topic-map-summary";
import concatClass from "discourse/helpers/concat-class";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const MIN_POST_READ_TIME = 4;
export default class TopicMap extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "collapsed", [tracked], function () {
    return !this.args.model.has_summary;
  }))();
  #collapsed = (() => (dt7948.i(this, "collapsed"), void 0))();
  get userFilters() {
    return this.args.postStream.userFilters || [];
  }
  toggleMap() {
    this.collapsed = !this.collapsed;
  }
  static #_3 = (() => dt7948.n(this.prototype, "toggleMap", [action]))();
  get topRepliesSummaryInfo() {
    if (this.topRepliesSummaryEnabled) {
      return I18n.t("summary.enabled_description");
    }
    const wordCount1 = this.args.model.word_count;
    if (wordCount1 && this.siteSettings.read_time_word_count > 0) {
      const readingTime1 = Math.ceil(Math.max(wordCount1 / this.siteSettings.read_time_word_count, this.args.model.posts_count * MIN_POST_READ_TIME / 60));
      return I18n.messageFormat("summary.description_time_MF", {
        replyCount: this.args.model.replyCount,
        readingTime: readingTime1
      });
    }
    return I18n.t("summary.description", {
      count: this.args.model.replyCount
    });
  }
  get topRepliesTitle() {
    if (this.topRepliesSummaryEnabled) {
      return;
    }
    return I18n.t("summary.short_title");
  }
  get topRepliesLabel() {
    const label1 = this.topRepliesSummaryEnabled ? "summary.disable" : "summary.enable";
    return I18n.t(label1);
  }
  get topRepliesIcon() {
    if (this.topRepliesSummaryEnabled) {
      return;
    }
    return "layer-group";
  }
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <section class={{concatClass "map" (if this.collapsed "map-collapsed")}}>
        <TopicMapSummary
          @topic={{@model}}
          @topicDetails={{@topicDetails}}
          @toggleMap={{this.toggleMap}}
          @collapsed={{this.collapsed}}
          @userFilters={{this.userFilters}}
        />
      </section>
      {{#unless this.collapsed}}
        <section
          class="topic-map-expanded"
          id="topic-map-expanded__aria-controls"
        >
          <TopicMapExpanded
            @topicDetails={{@topicDetails}}
            @userFilters={{this.userFilters}}
          />
        </section>
      {{/unless}}
  
      <section class="information toggle-summary">
        {{#if @model.has_summary}}
          <p>{{htmlSafe this.topRepliesSummaryInfo}}</p>
        {{/if}}
        <PluginOutlet
          @name="topic-map-expanded-after"
          @defaultGlimmer={{true}}
          @outletArgs={{hash topic=@model postStream=@postStream}}
        >
          {{#if @model.has_summary}}
            <DButton
              @action={{if @postStream.summary @cancelFilter @showTopReplies}}
              @translatedTitle={{this.topRepliesTitle}}
              @translatedLabel={{this.topRepliesLabel}}
              @icon={{this.topRepliesIcon}}
              class="top-replies"
            />
          {{/if}}
        </PluginOutlet>
      </section>
  
      {{#if @showPMMap}}
        <section class="information private-message-map">
          <PrivateMessageMap
            @topicDetails={{@topicDetails}}
            @showInvite={{@showInvite}}
            @removeAllowedGroup={{@removeAllowedGroup}}
            @removeAllowedUser={{@removeAllowedUser}}
          />
        </section>
      {{/if}}
    
  */
  {
    "id": "R5Q+MCbo",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[15,0,[28,[32,0],[\"map\",[52,[30,0,[\"collapsed\"]],\"map-collapsed\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@topic\",\"@topicDetails\",\"@toggleMap\",\"@collapsed\",\"@userFilters\"],[[30,1],[30,2],[30,0,[\"toggleMap\"]],[30,0,[\"collapsed\"]],[30,0,[\"userFilters\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"collapsed\"]]],[[[1,\"      \"],[10,\"section\"],[14,0,\"topic-map-expanded\"],[14,1,\"topic-map-expanded__aria-controls\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@topicDetails\",\"@userFilters\"],[[30,2],[30,0,[\"userFilters\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,\"section\"],[14,0,\"information toggle-summary\"],[12],[1,\"\\n\"],[41,[30,1,[\"has_summary\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[32,3],[[30,0,[\"topRepliesSummaryInfo\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[32,4],null,[[\"@name\",\"@defaultGlimmer\",\"@outletArgs\"],[\"topic-map-expanded-after\",true,[28,[32,5],null,[[\"topic\",\"postStream\"],[[30,1],[30,3]]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"has_summary\"]],[[[1,\"          \"],[8,[32,6],[[24,0,\"top-replies\"]],[[\"@action\",\"@translatedTitle\",\"@translatedLabel\",\"@icon\"],[[52,[30,3,[\"summary\"]],[30,4],[30,5]],[30,0,[\"topRepliesTitle\"]],[30,0,[\"topRepliesLabel\"]],[30,0,[\"topRepliesIcon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"      \"],[10,\"section\"],[14,0,\"information private-message-map\"],[12],[1,\"\\n        \"],[8,[32,7],null,[[\"@topicDetails\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],[[30,2],[30,7],[30,8],[30,9]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\",\"@topicDetails\",\"@postStream\",\"@cancelFilter\",\"@showTopReplies\",\"@showPMMap\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map.js",
    "scope": () => [concatClass, TopicMapSummary, TopicMapExpanded, htmlSafe, PluginOutlet, hash, DButton, PrivateMessageMap],
    "isStrictMode": true
  }), this))();
}