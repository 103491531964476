import { hash } from "@ember/helper";
import FKField from "discourse/form-kit/components/fk/field";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKControlInputGroup = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="form-kit__input-group">
    {{yield
      (hash
        Field=(component
          FKField
          errors=@errors
          addError=@addError
          data=@data
          set=@set
          remove=@remove
          registerField=@registerField
          unregisterField=@unregisterField
          triggerRevalidationFor=@triggerRevalidationFor
          showMeta=false
        )
      )
    }}
  </div>

*/
{
  "id": "h9SDfvjd",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"form-kit__input-group\"],[12],[1,\"\\n    \"],[18,9,[[28,[32,0],null,[[\"Field\"],[[50,[32,1],0,null,[[\"errors\",\"addError\",\"data\",\"set\",\"remove\",\"registerField\",\"unregisterField\",\"triggerRevalidationFor\",\"showMeta\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],false]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@errors\",\"@addError\",\"@data\",\"@set\",\"@remove\",\"@registerField\",\"@unregisterField\",\"@triggerRevalidationFor\",\"&default\"],false,[\"yield\",\"component\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/input-group.js",
  "scope": () => [hash, FKField],
  "isStrictMode": true
}), templateOnly());
export default FKControlInputGroup;