import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class UserActivityBookmarksWithReminders extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  queryParams = {
    q: {
      replace: true
    }
  };
  redirect() {
    this.router.transitionTo("userActivity.bookmarks");
  }
}