import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import IconPicker from "select-kit/components/icon-picker";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlIcon extends Component {
  static controlType = "icon";
  handleInput(value1) {
    this.args.field.set(value1);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <IconPicker
        @value={{readonly @value}}
        @onlyAvailable={{true}}
        @options={{hash
          maximum=1
          disabled=@field.disabled
          caretDownIcon="angle-down"
          caretUpIcon="angle-up"
          icons=@value
        }}
        @onChange={{this.handleInput}}
        class="form-kit__control-icon"
      />
    
  */
  {
    "id": "sPaY92gl",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-icon\"]],[[\"@value\",\"@onlyAvailable\",\"@options\",\"@onChange\"],[[28,[31,0],[[30,1]],null],true,[28,[32,1],null,[[\"maximum\",\"disabled\",\"caretDownIcon\",\"caretUpIcon\",\"icons\"],[1,[30,2,[\"disabled\"]],\"angle-down\",\"angle-up\",[30,1]]]],[30,0,[\"handleInput\"]]]],null],[1,\"\\n  \"]],[\"@value\",\"@field\"],false,[\"readonly\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/icon.js",
    "scope": () => [IconPicker, hash],
    "isStrictMode": true
  }), this))();
}