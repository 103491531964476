import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { modifier as modifierFn } from "ember-modifier";
import DButton from "discourse/components/d-button";
import DiscourseLinkedText from "discourse/components/discourse-linked-text";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const USER_DISMISSED_PROMPT_KEY = "dismissed-pwa-install-banner";
export default class PwaInstallBanner extends Component {
  static #_ = (() => dt7948.g(this.prototype, "capabilities", [service]))();
  #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "keyValueStore", [service]))();
  #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "bannerDismissed", [tracked], function () {
    return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY) === "true";
  }))();
  #bannerDismissed = (() => (dt7948.i(this, "bannerDismissed"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "deferredInstallPromptEvent", [tracked], function () {
    return null;
  }))();
  #deferredInstallPromptEvent = (() => (dt7948.i(this, "deferredInstallPromptEvent"), void 0))();
  registerInstallPromptListener = (() => modifierFn(() => {
    const handler1 = event1 => {
      // Prevent Chrome 76+ from automatically showing the prompt
      event1.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredInstallPromptEvent = event1;
    };
    window.addEventListener("beforeinstallprompt", handler1);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler1);
    };
  }))();
  get showPWAInstallBanner() {
    return this.capabilities.isAndroid && this.currentUser?.trust_level > 0 && this.deferredInstallPromptEvent &&
    // Pass the browser engagement checks
    !window.matchMedia("(display-mode: standalone)").matches &&
    // Not be in the installed PWA already
    !this.capabilities.isAppWebview &&
    // not launched via official app
    !this.bannerDismissed // Have not a previously dismissed install banner
    ;
  }
  turnOn() {
    this.dismiss();
    this.deferredInstallPromptEvent.prompt();
  }
  static #_7 = (() => dt7948.n(this.prototype, "turnOn", [action]))();
  dismiss() {
    this.keyValueStore.set({
      key: USER_DISMISSED_PROMPT_KEY,
      value: true
    });
    this.bannerDismissed = true;
  }
  static #_8 = (() => dt7948.n(this.prototype, "dismiss", [action]))();
  static #_9 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.showPWAInstallBanner}}
        <div class="pwa-install-banner alert alert-info">
          <span>
            <DiscourseLinkedText
              @action={{this.turnOn}}
              @text="pwa.install_banner"
              @textParams={{hash title=this.siteSettings.title}}
            />
          </span>
          <DButton
            @icon="times"
            @action={{this.dismiss}}
            @title="banner.close"
            class="btn-transparent close"
          />
        </div>
      {{/if}}
    
  */
  {
    "id": "zM3RL7ab",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showPWAInstallBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"pwa-install-banner alert alert-info\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@action\",\"@text\",\"@textParams\"],[[30,0,[\"turnOn\"]],\"pwa.install_banner\",[28,[32,1],null,[[\"title\"],[[30,0,[\"siteSettings\",\"title\"]]]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,0,[\"dismiss\"]],\"banner.close\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/pwa-install-banner.js",
    "scope": () => [DiscourseLinkedText, hash, DButton],
    "isStrictMode": true
  }), this))();
}