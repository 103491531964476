import { concat } from "@ember/helper";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKCol = setComponentTemplate(createTemplateFactory(
/*
  
  <div class={{concatClass "form-kit__col" (if @size (concat "--col-" @size))}}>
    {{yield}}
  </div>

*/
{
  "id": "rez9encz",
  "block": "[[[1,\"\\n  \"],[10,0],[15,0,[28,[32,0],[\"form-kit__col\",[52,[30,1],[28,[32,1],[\"--col-\",[30,1]],null]]],null]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@size\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/col.js",
  "scope": () => [concatClass, concat],
  "isStrictMode": true
}), templateOnly());
export default FKCol;