import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import formatDuration from "discourse/helpers/format-duration";
import number from "discourse/helpers/number";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserStat extends Component {
  get type() {
    return this.args.type ?? "number";
  }
  get isNumber() {
    return this.type === "number";
  }
  get isDuration() {
    return this.type === "duration";
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="user-stat">
        <span class="value" title={{@rawTitle}}>
          {{#if this.isNumber}}
            {{number @value}}
          {{else if this.isDuration}}
            {{formatDuration @value}}
          {{else}}
            {{@value}}
          {{/if}}
        </span>
        <span class="label">
          {{#if @icon}}{{icon @icon}}{{/if}}
          {{htmlSafe (i18n @label count=@value)}}
        </span>
      </div>
    
  */
  {
    "id": "Emlxa88I",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"user-stat\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"value\"],[15,\"title\",[30,1]],[12],[1,\"\\n\"],[41,[30,0,[\"isNumber\"]],[[[1,\"          \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isDuration\"]],[[[1,\"          \"],[1,[28,[32,1],[[30,2]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,2]],[1,\"\\n        \"]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n        \"],[41,[30,3],[[[1,[28,[32,2],[[30,3]],null]]],[]],null],[1,\"\\n        \"],[1,[28,[32,3],[[28,[32,4],[[30,4]],[[\"count\"],[[30,2]]]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@rawTitle\",\"@value\",\"@icon\",\"@label\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-stat.js",
    "scope": () => [number, formatDuration, icon, htmlSafe, i18n],
    "isStrictMode": true
  }), this))();
}