import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKAlert extends Component {
  get type() {
    return this.args.type || "info";
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="form-kit__alert alert alert-{{this.type}}" ...attributes>
        {{#if @icon}}
          {{icon @icon}}
        {{/if}}
  
        <span class="form-kit__alert-message">{{yield}}</span>
      </div>
    
  */
  {
    "id": "8cxEw1Wf",
    "block": "[[[1,\"\\n    \"],[11,0],[16,0,[29,[\"form-kit__alert alert alert-\",[30,0,[\"type\"]]]]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,1],[14,0,\"form-kit__alert-message\"],[12],[18,3,null],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@icon\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/alert.js",
    "scope": () => [icon],
    "isStrictMode": true
  }), this))();
}