import Component from "@glimmer/component";
import { service } from "@ember/service";
import CustomSection from "./custom-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SidebarCustomSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "messageBus", [service]))();
  #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "topicTrackingState", [service]))();
  #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  anonymous = false;
  get sections() {
    if (this.anonymous) {
      return this.site.anonymous_sidebar_sections;
    } else {
      return this.currentUser.sidebarSections;
    }
  }
  static #_8 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="sidebar-custom-sections">
        {{#each this.sections as |section|}}
          <CustomSection @sectionData={{section}} @collapsable={{@collapsable}} />
        {{/each}}
      </div>
    
  */
  {
    "id": "RTfM/mf4",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-custom-sections\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@sectionData\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"section\",\"@collapsable\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/common/custom-sections.js",
    "scope": () => [CustomSection],
    "isStrictMode": true
  }), this))();
}