import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.field.name}}
  <label class="control-label">
    {{this.field.name}}
    {{~#unless this.field.required}} {{i18n "user_fields.optional"}}{{/unless~}}
  </label>
{{/if}}

<div class="controls">
  <label class="control-label checkbox-label">
    <Input
      id={{concat "user-" this.elementId}}
      @checked={{this.value}}
      @type="checkbox"
    />
    <span>
      {{html-safe this.field.description}}
      {{#unless this.field.name}}{{#if this.field.required}}<span
            class="required"
          >*</span>{{/if}}{{/unless}}
    </span>
  </label>
</div>
*/
{
  "id": "uVkzbMQ+",
  "block": "[[[41,[30,0,[\"field\",\"name\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"field\",\"name\"]]],[41,[51,[30,0,[\"field\",\"required\"]]],[[[1,\" \"],[1,[28,[35,2],[\"user_fields.optional\"],null]]],[]],null],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label checkbox-label\"],[12],[1,\"\\n    \"],[8,[39,3],[[16,1,[28,[37,4],[\"user-\",[30,0,[\"elementId\"]]],null]]],[[\"@checked\",\"@type\"],[[30,0,[\"value\"]],\"checkbox\"]],null],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,0,[\"field\",\"description\"]]],null]],[1,\"\\n      \"],[41,[51,[30,0,[\"field\",\"name\"]]],[[[41,[30,0,[\"field\",\"required\"]],[[[10,1],[14,0,\"required\"],[12],[1,\"*\"],[13]],[]],null]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"unless\",\"i18n\",\"input\",\"concat\",\"html-safe\"]]",
  "moduleName": "discourse/components/user-fields/confirm.hbs",
  "isStrictMode": false
});