import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class AuthButtons extends Component {
  static #_ = (() => dt7948.g(this.prototype, "header", [service]))();
  #header = (() => (dt7948.i(this, "header"), void 0))();
  get showSignupButton() {
    return this.args.canSignUp && !this.header.headerButtonsHidden.includes("signup") && !this.header.topicInfoVisible;
  }
  get showLoginButton() {
    return !this.header.headerButtonsHidden.includes("login");
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <span class="auth-buttons">
        {{#if this.showSignupButton}}
          <DButton
            class="btn-primary btn-small sign-up-button"
            @action={{@showCreateAccount}}
            @label="sign_up"
          />
        {{/if}}
  
        {{#if this.showLoginButton}}
          <DButton
            class="btn-primary btn-small login-button"
            @action={{@showLogin}}
            @label="log_in"
            @icon="user"
          />
        {{/if}}
      </span>
    
  */
  {
    "id": "RsH2KzG3",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"auth-buttons\"],[12],[1,\"\\n\"],[41,[30,0,[\"showSignupButton\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary btn-small sign-up-button\"]],[[\"@action\",\"@label\"],[[30,1],\"sign_up\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showLoginButton\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary btn-small login-button\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,2],\"log_in\",\"user\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@showCreateAccount\",\"@showLogin\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/auth-buttons.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this))();
}