import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import FKControlConditionalDisplayCondition from "./conditional-content/condition";
import FKControlConditionalContentContent from "./conditional-content/content";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const Conditions = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="form-kit__inline-radio">
    {{yield
      (component
        FKControlConditionalDisplayCondition
        activeName=@activeName
        setCondition=@setCondition
      )
    }}
  </div>

*/
{
  "id": "YWz5JxSS",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"form-kit__inline-radio\"],[12],[1,\"\\n    \"],[18,3,[[50,[32,0],0,null,[[\"activeName\",\"setCondition\"],[[30,1],[30,2]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@activeName\",\"@setCondition\",\"&default\"],false,[\"yield\",\"component\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/conditional-content.js",
  "scope": () => [FKControlConditionalDisplayCondition],
  "isStrictMode": true
}), templateOnly());
const Contents = setComponentTemplate(createTemplateFactory(
/*
  
  {{yield
    (component FKControlConditionalContentContent activeName=@activeName)
  }}

*/
{
  "id": "TWiPdggF",
  "block": "[[[1,\"\\n  \"],[18,2,[[50,[32,0],0,null,[[\"activeName\"],[[30,1]]]]]],[1,\"\\n\"]],[\"@activeName\",\"&default\"],false,[\"yield\",\"component\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/conditional-content.js",
  "scope": () => [FKControlConditionalContentContent],
  "isStrictMode": true
}), templateOnly());
export default class FKControlConditionalContent extends Component {
  static #_ = (() => dt7948.g(this.prototype, "activeName", [tracked], function () {
    return this.args.activeName;
  }))();
  #activeName = (() => (dt7948.i(this, "activeName"), void 0))();
  setCondition(name1) {
    this.activeName = name1;
  }
  static #_2 = (() => dt7948.n(this.prototype, "setCondition", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="form-kit__conditional-display">
        {{yield
          (hash
            Conditions=(component
              Conditions activeName=this.activeName setCondition=this.setCondition
            )
            Contents=(component Contents activeName=this.activeName)
          )
        }}
      </div>
    
  */
  {
    "id": "biThpBVH",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"form-kit__conditional-display\"],[12],[1,\"\\n      \"],[18,1,[[28,[32,0],null,[[\"Conditions\",\"Contents\"],[[50,[32,1],0,null,[[\"activeName\",\"setCondition\"],[[30,0,[\"activeName\"]],[30,0,[\"setCondition\"]]]]],[50,[32,2],0,null,[[\"activeName\"],[[30,0,[\"activeName\"]]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/conditional-content.js",
    "scope": () => [hash, Conditions, Contents],
    "isStrictMode": true
  }), this))();
}