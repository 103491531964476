import SearchMenuPanel from "../search-menu-panel";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SearchMenuWrapper = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>

*/
{
  "id": "x466McI6",
  "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"search-menu glimmer-search-menu\"],[24,\"aria-live\",\"polite\"],[17,1],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@closeSearchMenu\"],[[30,2]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@closeSearchMenu\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/search-menu-wrapper.js",
  "scope": () => [SearchMenuPanel],
  "isStrictMode": true
}), templateOnly());
export default SearchMenuWrapper;