import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
const LIST_TYPE = {
  NORMAL: "normal",
  MUTED: "muted"
};
export default Component.extend(dt7948.p({
  tagName: "",
  category: null,
  listType: LIST_TYPE.NORMAL,
  isHidden(isHiddenCategory, hasMuted, listType) {
    return isHiddenCategory && listType === LIST_TYPE.NORMAL || !hasMuted && listType === LIST_TYPE.MUTED;
  },
  isMuted(isMutedCategory, listType) {
    return isMutedCategory && listType === LIST_TYPE.NORMAL || !isMutedCategory && listType === LIST_TYPE.MUTED;
  },
  unreadTopicsCount() {
    return this.category.unreadTopicsCount;
  },
  newTopicsCount() {
    return this.category.newTopicsCount;
  },
  slugPath(categoryPath) {
    return categoryPath.substring("/c/".length);
  }
}, [["method", "isHidden", [discourseComputed("category.isHidden", "category.hasMuted", "listType")]], ["method", "isMuted", [discourseComputed("category.isMuted", "listType")]], ["method", "unreadTopicsCount", [discourseComputed("topicTrackingState.messageCount")]], ["method", "newTopicsCount", [discourseComputed("topicTrackingState.messageCount")]], ["method", "slugPath", [discourseComputed("category.path")]]]));