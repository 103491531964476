import Component from "@glimmer/component";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlMenuItem extends Component {
  handleInput() {
    this.args.menuApi.close();
    if (this.args.action) {
      this.args.action(this.args.value, {
        set: this.args.set
      });
    } else {
      this.args.field.set(this.args.value);
    }
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <@item class="form-kit__control-menu-item" data-value={{@value}}>
        <DButton
          @action={{this.handleInput}}
          class="btn-flat"
          @icon={{@icon}}
          ...attributes
        >
          {{yield}}
        </DButton>
      </@item>
    
  */
  {
    "id": "ljDoDPGu",
    "block": "[[[1,\"\\n    \"],[8,[30,1],[[24,0,\"form-kit__control-menu-item\"],[16,\"data-value\",[30,2]]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-flat\"],[17,3]],[[\"@action\",\"@icon\"],[[30,0,[\"handleInput\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[18,5,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@item\",\"@value\",\"&attrs\",\"@icon\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/menu/item.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this))();
}