import FormText from "discourse/form-kit/components/fk/text";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKContainer = setComponentTemplate(createTemplateFactory(
/*
  
  <div class={{concatClass "form-kit__container" @class}} ...attributes>
    {{#if @title}}
      <span class="form-kit__container-title">
        {{@title}}
      </span>
    {{/if}}

    {{#if @subtitle}}
      <FormText class="form-kit__container-subtitle">{{@subtitle}}</FormText>
    {{/if}}

    <div class="form-kit__container-content">
      {{yield}}
    </div>
  </div>

*/
{
  "id": "1YhtsGZF",
  "block": "[[[1,\"\\n  \"],[11,0],[16,0,[28,[32,0],[\"form-kit__container\",[30,1]],null]],[17,2],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,1],[14,0,\"form-kit__container-title\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[32,1],[[24,0,\"form-kit__container-subtitle\"]],null,[[\"default\"],[[[[1,[30,4]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"form-kit__container-content\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"@title\",\"@subtitle\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/container.js",
  "scope": () => [concatClass, FormText],
  "isStrictMode": true
}), templateOnly());
export default FKContainer;