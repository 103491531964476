import Component from "@glimmer/component";
import { gt, lt } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKCharCounter extends Component {
  get currentLength() {
    return this.args.value?.length || 0;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <span
        class={{concatClass
          "form-kit__char-counter"
          (if (gt this.currentLength @maxLength) "--exceeded")
          (if (lt this.currentLength @minLength) "--insufficient")
        }}
        ...attributes
      >
        {{this.currentLength}}/{{@maxLength}}
      </span>
    
  */
  {
    "id": "4J/bO9r/",
    "block": "[[[1,\"\\n    \"],[11,1],[16,0,[28,[32,0],[\"form-kit__char-counter\",[52,[28,[32,1],[[30,0,[\"currentLength\"]],[30,1]],null],\"--exceeded\"],[52,[28,[32,2],[[30,0,[\"currentLength\"]],[30,2]],null],\"--insufficient\"]],null]],[17,3],[12],[1,\"\\n      \"],[1,[30,0,[\"currentLength\"]]],[1,\"/\"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@maxLength\",\"@minLength\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/char-counter.js",
    "scope": () => [concatClass, gt, lt],
    "isStrictMode": true
  }), this))();
}