import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKRow = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>

*/
{
  "id": "ziBXeloV",
  "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"form-kit__row\"],[17,1],[12],[1,\"\\n    \"],[18,2,[[28,[32,0],null,[[\"Col\"],[[32,1]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/row.js",
  "scope": () => [hash, FKCol],
  "isStrictMode": true
}), templateOnly());
export default FKRow;