import loaderShim from "discourse-common/lib/loader-shim";

// AMD shims for the app bundle, see the comment in loader-shim.js
// These effectively become public APIs for plugins, so add/remove them carefully
import esc from "../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
loaderShim("@discourse/itsatrap", () => esc(require("@discourse/itsatrap")));
loaderShim("@ember-compat/tracked-built-ins", () => esc(require("@ember-compat/tracked-built-ins")));
loaderShim("@popperjs/core", () => esc(require("@popperjs/core")));
loaderShim("@floating-ui/dom", () => esc(require("@floating-ui/dom")));
loaderShim("@uppy/aws-s3", () => esc(require("@uppy/aws-s3")));
loaderShim("@uppy/aws-s3-multipart", () => esc(require("@uppy/aws-s3-multipart")));
loaderShim("@uppy/core", () => esc(require("@uppy/core")));
loaderShim("@uppy/drop-target", () => esc(require("@uppy/drop-target")));
loaderShim("@uppy/utils/lib/AbortController", () => esc(require("@uppy/utils/lib/AbortController")));
loaderShim("@uppy/utils/lib/delay", () => esc(require("@uppy/utils/lib/delay")));
loaderShim("@uppy/utils/lib/EventTracker", () => esc(require("@uppy/utils/lib/EventTracker")));
loaderShim("@uppy/xhr-upload", () => esc(require("@uppy/xhr-upload")));
loaderShim("a11y-dialog", () => esc(require("a11y-dialog")));
loaderShim("discourse-i18n", () => esc(require("discourse-i18n")));
loaderShim("ember-modifier", () => esc(require("ember-modifier")));
loaderShim("ember-route-template", () => esc(require("ember-route-template")));
loaderShim("handlebars", () => esc(require("handlebars")));
loaderShim("jquery", () => esc(require("jquery")));
loaderShim("js-yaml", () => esc(require("js-yaml")));
loaderShim("message-bus-client", () => esc(require("message-bus-client")));
loaderShim("virtual-dom", () => esc(require("virtual-dom")));
loaderShim("xss", () => esc(require("xss")));
loaderShim("truth-helpers", () => esc(require("truth-helpers")));
loaderShim("truth-helpers/helpers/and", () => esc(require("truth-helpers/helpers/and")));
loaderShim("truth-helpers/helpers/eq", () => esc(require("truth-helpers/helpers/eq")));
loaderShim("truth-helpers/helpers/gt", () => esc(require("truth-helpers/helpers/gt")));
loaderShim("truth-helpers/helpers/gte", () => esc(require("truth-helpers/helpers/gte")));
loaderShim("truth-helpers/helpers/includes", () => esc(require("truth-helpers/helpers/includes")));
loaderShim("truth-helpers/helpers/lt", () => esc(require("truth-helpers/helpers/lt")));
loaderShim("truth-helpers/helpers/lte", () => esc(require("truth-helpers/helpers/lte")));
loaderShim("truth-helpers/helpers/not-eq", () => esc(require("truth-helpers/helpers/not-eq")));
loaderShim("truth-helpers/helpers/not", () => esc(require("truth-helpers/helpers/not")));
loaderShim("truth-helpers/helpers/or", () => esc(require("truth-helpers/helpers/or")));
loaderShim("@messageformat/runtime/messages", () => esc(require("@messageformat/runtime/messages")));
loaderShim("@messageformat/runtime", () => esc(require("@messageformat/runtime")));
loaderShim("@messageformat/runtime/lib/cardinals", () => esc(require("@messageformat/runtime/lib/cardinals")));