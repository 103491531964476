import { or } from "truth-helpers";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const GroupsInfo = setComponentTemplate(createTemplateFactory(
/*
  
  <span class="group-info-details">
    <span class="groups-info-name">
      {{or @group.full_name @group.displayName}}
    </span>
  </span>

*/
{
  "id": "PNlju7B7",
  "block": "[[[1,\"\\n  \"],[10,1],[14,0,\"group-info-details\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"groups-info-name\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1,[\"full_name\"]],[30,1,[\"displayName\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@group\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/groups-info.js",
  "scope": () => [or],
  "isStrictMode": true
}), templateOnly());
export default GroupsInfo;