import Section from "./section";
import SectionLink from "./section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarApiSection = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @section.filtered}}
    <Section
      @sectionName={{@section.name}}
      @headerLinkText={{@section.text}}
      @headerLinkTitle={{@section.title}}
      @headerActionsIcon={{@section.actionsIcon}}
      @headerActions={{@section.actions}}
      @willDestroy={{@section.willDestroy}}
      @collapsable={{@collapsable}}
      @displaySection={{@section.displaySection}}
      @hideSectionHeader={{@section.hideSectionHeader}}
      @collapsedByDefault={{@section.collapsedByDefault}}
    >
      {{#each @section.filteredLinks key="name" as |link|}}
        <SectionLink
          @linkName={{link.name}}
          @linkClass={{link.classNames}}
          @route={{link.route}}
          @model={{link.model}}
          @query={{link.query}}
          @models={{link.models}}
          @href={{link.href}}
          @title={{link.title}}
          @contentCSSClass={{link.contentCSSClass}}
          @prefixColor={{link.prefixColor}}
          @prefixBadge={{link.prefixBadge}}
          @prefixType={{link.prefixType}}
          @prefixValue={{link.prefixValue}}
          @prefixCSSClass={{link.prefixCSSClass}}
          @suffixType={{link.suffixType}}
          @suffixValue={{link.suffixValue}}
          @suffixCSSClass={{link.suffixCSSClass}}
          @hoverType={{link.hoverType}}
          @hoverValue={{link.hoverValue}}
          @hoverAction={{link.hoverAction}}
          @hoverTitle={{link.hoverTitle}}
          @currentWhen={{link.currentWhen}}
          @didInsert={{link.didInsert}}
          @willDestroy={{link.willDestroy}}
          @content={{link.text}}
          @contentComponent={{component
            link.contentComponent
            status=link.contentComponentArgs
          }}
        />
      {{/each}}
    </Section>
  {{/if}}

*/
{
  "id": "xKBkAB5O",
  "block": "[[[1,\"\\n\"],[41,[30,1,[\"filtered\"]],[[[1,\"    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@headerLinkTitle\",\"@headerActionsIcon\",\"@headerActions\",\"@willDestroy\",\"@collapsable\",\"@displaySection\",\"@hideSectionHeader\",\"@collapsedByDefault\"],[[30,1,[\"name\"]],[30,1,[\"text\"]],[30,1,[\"title\"]],[30,1,[\"actionsIcon\"]],[30,1,[\"actions\"]],[30,1,[\"willDestroy\"]],[30,2],[30,1,[\"displaySection\"]],[30,1,[\"hideSectionHeader\"]],[30,1,[\"collapsedByDefault\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"filteredLinks\"]]],null]],null],\"name\",[[[1,\"        \"],[8,[32,1],null,[[\"@linkName\",\"@linkClass\",\"@route\",\"@model\",\"@query\",\"@models\",\"@href\",\"@title\",\"@contentCSSClass\",\"@prefixColor\",\"@prefixBadge\",\"@prefixType\",\"@prefixValue\",\"@prefixCSSClass\",\"@suffixType\",\"@suffixValue\",\"@suffixCSSClass\",\"@hoverType\",\"@hoverValue\",\"@hoverAction\",\"@hoverTitle\",\"@currentWhen\",\"@didInsert\",\"@willDestroy\",\"@content\",\"@contentComponent\"],[[30,3,[\"name\"]],[30,3,[\"classNames\"]],[30,3,[\"route\"]],[30,3,[\"model\"]],[30,3,[\"query\"]],[30,3,[\"models\"]],[30,3,[\"href\"]],[30,3,[\"title\"]],[30,3,[\"contentCSSClass\"]],[30,3,[\"prefixColor\"]],[30,3,[\"prefixBadge\"]],[30,3,[\"prefixType\"]],[30,3,[\"prefixValue\"]],[30,3,[\"prefixCSSClass\"]],[30,3,[\"suffixType\"]],[30,3,[\"suffixValue\"]],[30,3,[\"suffixCSSClass\"]],[30,3,[\"hoverType\"]],[30,3,[\"hoverValue\"]],[30,3,[\"hoverAction\"]],[30,3,[\"hoverTitle\"]],[30,3,[\"currentWhen\"]],[30,3,[\"didInsert\"]],[30,3,[\"willDestroy\"]],[30,3,[\"text\"]],[50,[30,3,[\"contentComponent\"]],0,null,[[\"status\"],[[30,3,[\"contentComponentArgs\"]]]]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@section\",\"@collapsable\",\"link\"],false,[\"if\",\"each\",\"-track-array\",\"component\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-section.js",
  "scope": () => [Section, SectionLink],
  "isStrictMode": true
}), templateOnly());
export default SidebarApiSection;