import Component from "@glimmer/component";
import { service } from "@ember/service";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FilterNoResults extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get shouldDisplay() {
    return this.sidebarState.currentPanel.filterable && !!(this.args.sections?.length === 0);
  }
  get noResultsDescription() {
    return this.sidebarState.currentPanel.filterNoResultsDescription(this.sidebarState.filter);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldDisplay}}
        <div class="sidebar-no-results">
          <h4 class="sidebar-no-results__title">{{i18n
              "sidebar.no_results.title"
            }}</h4>
          {{#if this.noResultsDescription}}
            <p class="sidebar-no-results__description">
              {{this.noResultsDescription}}
            </p>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "wEbq1P9d",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-no-results\"],[12],[1,\"\\n        \"],[10,\"h4\"],[14,0,\"sidebar-no-results__title\"],[12],[1,[28,[32,0],[\"sidebar.no_results.title\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"noResultsDescription\"]],[[[1,\"          \"],[10,2],[14,0,\"sidebar-no-results__description\"],[12],[1,\"\\n            \"],[1,[30,0,[\"noResultsDescription\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/filter-no-results.js",
    "scope": () => [i18n],
    "isStrictMode": true
  }), this))();
}