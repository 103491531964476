import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class CdnImg extends Component {
  get cdnSrc() {
    return getURLWithCDN(this.args.src);
  }
  get style() {
    if (this.args.width && this.args.height) {
      return htmlSafe(`--aspect-ratio: ${this.args.width / this.args.height};`);
    }
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if @src}}
        <img
          ...attributes
          src={{this.cdnSrc}}
          width={{@width}}
          height={{@height}}
          style={{this.style}}
          alt=""
        />
      {{/if}}
    
  */
  {
    "id": "NED4BHCL",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,\"img\"],[17,2],[16,\"src\",[30,0,[\"cdnSrc\"]]],[16,\"width\",[30,3]],[16,\"height\",[30,4]],[16,5,[30,0,[\"style\"]]],[24,\"alt\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@src\",\"&attrs\",\"@width\",\"@height\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/cdn-img.js",
    "isStrictMode": true
  }), this))();
}