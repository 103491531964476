import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import DismissReadModal from "discourse/components/modal/dismiss-read";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicDismissButtons extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  get showBasedOnPosition() {
    return this.args.position === "top" || this.args.model.topics.length > 5;
  }
  get dismissLabel() {
    if (this.args.selectedTopics.length === 0) {
      return I18n.t("topics.bulk.dismiss_button");
    }
    return I18n.t("topics.bulk.dismiss_button_with_selected", {
      count: this.args.selectedTopics.length
    });
  }
  get dismissNewLabel() {
    if (this.currentUser?.new_new_view_enabled) {
      return I18n.t("topics.bulk.dismiss_button");
    }
    if (this.args.selectedTopics.length === 0) {
      return I18n.t("topics.bulk.dismiss_new");
    }
    return I18n.t("topics.bulk.dismiss_new_with_selected", {
      count: this.args.selectedTopics.length
    });
  }
  dismissReadPosts() {
    this.modal.show(DismissReadModal, {
      model: {
        title: this.args.selectedTopics.length ? "topics.bulk.dismiss_read_with_selected" : "topics.bulk.dismiss_read",
        count: this.args.selectedTopics.length,
        dismissRead: this.args.dismissRead
      }
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "dismissReadPosts", [action]))();
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{~#if this.showBasedOnPosition~}}
        <div class="row dismiss-container-{{@position}}">
          {{~#if @showDismissRead~}}
            <DButton
              @action={{this.dismissReadPosts}}
              @translatedLabel={{this.dismissLabel}}
              @title="topics.bulk.dismiss_tooltip"
              id="dismiss-topics-{{@position}}"
              class="btn-default dismiss-read"
            />
          {{~/if~}}
          {{~#if @showResetNew~}}
            <DButton
              @action={{@resetNew}}
              @translatedLabel={{this.dismissNewLabel}}
              @icon="check"
              id="dismiss-new-{{@position}}"
              class="btn-default dismiss-read"
            />
          {{~/if~}}
        </div>
      {{~/if~}}
    
  */
  {
    "id": "Vh0Unk3g",
    "block": "[[[41,[30,0,[\"showBasedOnPosition\"]],[[[10,0],[15,0,[29,[\"row dismiss-container-\",[30,1]]]],[12],[41,[30,2],[[[8,[32,0],[[16,1,[29,[\"dismiss-topics-\",[30,1]]]],[24,0,\"btn-default dismiss-read\"]],[[\"@action\",\"@translatedLabel\",\"@title\"],[[30,0,[\"dismissReadPosts\"]],[30,0,[\"dismissLabel\"]],\"topics.bulk.dismiss_tooltip\"]],null]],[]],null],[41,[30,3],[[[8,[32,0],[[16,1,[29,[\"dismiss-new-\",[30,1]]]],[24,0,\"btn-default dismiss-read\"]],[[\"@action\",\"@translatedLabel\",\"@icon\"],[[30,4],[30,0,[\"dismissNewLabel\"]],\"check\"]],null]],[]],null],[13]],[]],null]],[\"@position\",\"@showDismissRead\",\"@showResetNew\",\"@resetNew\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-dismiss-buttons.js",
    "scope": () => [DButton],
    "isStrictMode": true
  }), this))();
}