import { LinkTo } from "@ember/routing";
import { defaultHomepage } from "discourse/lib/utilities";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const BackToForum = setComponentTemplate(createTemplateFactory(
/*
  
  <LinkTo
    @route="discovery.{{(defaultHomepage)}}"
    class="sidebar-sections__back-to-forum"
  >
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </LinkTo>

*/
{
  "id": "JvONDfUj",
  "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"sidebar-sections__back-to-forum\"]],[[\"@route\"],[[29,[\"discovery.\",[28,[32,1],null,null]]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[32,2],[\"arrow-left\"],null]],[1,\"\\n\\n    \"],[10,1],[12],[1,[28,[32,3],[\"sidebar.back_to_forum\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/back-to-forum.js",
  "scope": () => [LinkTo, defaultHomepage, icon, i18n],
  "isStrictMode": true
}), templateOnly());
export default BackToForum;