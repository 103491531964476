import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const GeneratedInviteLink = setComponentTemplate(createTemplateFactory(
/*
  
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>

*/
{
  "id": "ebu5JPrm",
  "block": "[[[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,2],[12],[1,[28,[32,0],[\"user.invited.link_generated\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"input\"],[15,2,[30,1]],[14,0,\"invite-link-input\"],[14,\"autofocus\",\"autofocus\"],[14,4,\"text\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,2],[12],[1,[28,[32,0],[\"user.invited.valid_for\"],[[\"email\"],[[30,2]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@link\",\"@email\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/generated-invite-link.js",
  "scope": () => [i18n],
  "isStrictMode": true
}), templateOnly());
export default GeneratedInviteLink;