import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class ReviewShow extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "store", [service]))();
  #store = (() => (dt7948.i(this, "store"), void 0))();
  model(_ref) {
    let {
      reviewable_id
    } = _ref;
    return this.store.find("reviewable", reviewable_id);
  }
  setupController(controller, model) {
    controller.set("reviewable", model);
  }
}