import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const EmptyState = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>

*/
{
  "id": "GyNqhby7",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"empty-state-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"empty-state-title\"],[12],[1,[30,1]],[13],[1,\"\\n      \"],[10,0],[14,0,\"empty-state-body\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[30,2]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@title\",\"@body\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/empty-state.js",
  "isStrictMode": true
}), templateOnly());
export default EmptyState;