import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DismissNotificationConfirmationModal from "discourse/components/modal/dismiss-notification-confirmation";
import RelativeDate from "discourse/components/relative-date";
import { ajax } from "discourse/lib/ajax";
import UserMenuNotificationItem from "discourse/lib/user-menu/notification-item";
import getURL from "discourse-common/lib/get-url";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const _beforeLoadMoreCallbacks = [];
export function addBeforeLoadMoreCallback(fn1) {
  _beforeLoadMoreCallbacks.push(fn1);
}
export default class UserNotificationsController extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "appEvents", [service]))();
  #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "site", [service]))();
  #site = (() => (dt7948.i(this, "site"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "filter", [tracked], function () {
    return "all";
  }))();
  #filter = (() => (dt7948.i(this, "filter"), void 0))();
  queryParams = ["filter"];
  get listContainerClassNames() {
    return `user-notifications-list ${this.siteSettings.show_user_menu_avatars ? "show-avatars" : ""}`;
  }
  isFiltered() {
    return this.filter && this.filter !== "all";
  }
  static #_7 = (() => dt7948.n(this.prototype, "isFiltered", [discourseComputed("filter")]))();
  items() {
    return this.model.map(notification => {
      const props = {
        appEvents: this.appEvents,
        currentUser: this.currentUser,
        siteSettings: this.siteSettings,
        site: this.site,
        notification,
        endComponent: setComponentTemplate(createTemplateFactory(
        /*
          
                  <RelativeDate @date={{notification.created_at}} />
                
        */
        {
          "id": "Nwrbyhur",
          "block": "[[[1,\"\\n          \"],[8,[32,0],null,[[\"@date\"],[[32,1,[\"created_at\"]]]],null],[1,\"\\n        \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/controllers/user-notifications.js",
          "scope": () => [RelativeDate, notification],
          "isStrictMode": true
        }), templateOnly())
      };
      return new UserMenuNotificationItem(props);
    });
  }
  static #_8 = (() => dt7948.n(this.prototype, "items", [discourseComputed("model.content.@each")]))();
  allNotificationsRead() {
    return !this.get("model.content").some(notification1 => !notification1.get("read"));
  }
  static #_9 = (() => dt7948.n(this.prototype, "allNotificationsRead", [discourseComputed("model.content.@each.read")]))();
  doesNotHaveNotifications(isFiltered1, contentLength1, loading1) {
    return !loading1 && !isFiltered1 && contentLength1 === 0;
  }
  static #_10 = (() => dt7948.n(this.prototype, "doesNotHaveNotifications", [discourseComputed("isFiltered", "model.content.length", "loading")]))();
  nothingFound(isFiltered1, contentLength1) {
    return isFiltered1 && contentLength1 === 0;
  }
  static #_11 = (() => dt7948.n(this.prototype, "nothingFound", [discourseComputed("isFiltered", "model.content.length")]))();
  emptyStateBody() {
    return htmlSafe(I18n.t("user.no_notifications_page_body", {
      preferencesUrl: getURL("/my/preferences/notifications"),
      icon: iconHTML("bell")
    }));
  }
  static #_12 = (() => dt7948.n(this.prototype, "emptyStateBody", [discourseComputed()]))();
  async markRead() {
    await ajax("/notifications/mark-read", {
      type: "PUT"
    });
    this.model.forEach(notification1 => notification1.set("read", true));
  }
  updateFilter(value1) {
    this.loading = true;
    this.filter = value1;
  }
  static #_13 = (() => dt7948.n(this.prototype, "updateFilter", [action]))();
  async resetNew() {
    if (this.currentUser.unread_high_priority_notifications > 0) {
      this.modal.show(DismissNotificationConfirmationModal, {
        model: {
          confirmationMessage: I18n.t("notifications.dismiss_confirmation.body.default", {
            count: this.currentUser.unread_high_priority_notifications
          }),
          dismissNotifications: () => this.markRead()
        }
      });
    } else {
      this.markRead();
    }
  }
  static #_14 = (() => dt7948.n(this.prototype, "resetNew", [action]))();
  loadMore() {
    if (_beforeLoadMoreCallbacks.length && !_beforeLoadMoreCallbacks.some(fn1 => fn1(this))) {
      // Return early if any callbacks return false, short-circuiting the default loading more logic
      return;
    }
    this.model.loadMore();
  }
  static #_15 = (() => dt7948.n(this.prototype, "loadMore", [action]))();
}