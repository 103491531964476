import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import FKText from "discourse/form-kit/components/fk/text";
import FKControlRadioGroupRadio from "./radio-group/radio";
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlRadioGroup extends Component {
  static controlType = "radio-group";
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <fieldset class="form-kit__radio-group" ...attributes>
        {{#if @title}}
          <legend class="form-kit__radio-group-title">{{@title}}</legend>
        {{/if}}
  
        {{#if @subtitle}}
          <FKText class="form-kit__radio-group-subtitle">
            {{@subtitle}}
          </FKText>
        {{/if}}
  
        {{yield
          (hash
            Radio=(component
              FKControlRadioGroupRadio groupValue=@value field=@field
            )
          )
        }}
      </fieldset>
    
  */
  {
    "id": "2712f2iN",
    "block": "[[[1,\"\\n    \"],[11,\"fieldset\"],[24,0,\"form-kit__radio-group\"],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[10,\"legend\"],[14,0,\"form-kit__radio-group-title\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[8,[32,0],[[24,0,\"form-kit__radio-group-subtitle\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[18,6,[[28,[32,1],null,[[\"Radio\"],[[50,[32,2],0,null,[[\"groupValue\",\"field\"],[[30,4],[30,5]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@title\",\"@subtitle\",\"@value\",\"@field\",\"&default\"],false,[\"if\",\"yield\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/radio-group.js",
    "scope": () => [FKText, hash, FKControlRadioGroupRadio],
    "isStrictMode": true
  }), this))();
}