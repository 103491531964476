import { eq } from "truth-helpers";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKControlConditionalContentItem = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}

*/
{
  "id": "LruuDd1L",
  "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[30,2]],null],[[[1,\"    \"],[10,0],[14,0,\"form-kit__conditional-display-content\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@name\",\"@activeName\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/conditional-content/content.js",
  "scope": () => [eq],
  "isStrictMode": true
}), templateOnly());
export default FKControlConditionalContentItem;