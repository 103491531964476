import { concat } from "@ember/helper";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const UserSummarySection = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>

*/
{
  "id": "jqFJC3+O",
  "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"top-sub-section\"],[17,1],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"stats-title\"],[12],[1,[28,[32,0],[[28,[32,1],[\"user.summary.\",[30,2]],null]],null]],[13],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-summary-section.js",
  "scope": () => [i18n, concat],
  "isStrictMode": true
}), templateOnly());
export default UserSummarySection;