import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKErrorsSummary extends Component {
  concatErrors(errors1) {
    return errors1.join(", ");
  }
  get hasErrors() {
    return Object.keys(this.args.errors).length > 0;
  }
  normalizeName(name1) {
    return name1.replace(/\./g, "-");
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.hasErrors}}
        <div class="form-kit__errors-summary" aria-live="assertive" ...attributes>
          <h2 class="form-kit__errors-summary-title">
            {{icon "exclamation-triangle"}}
            {{i18n "form_kit.errors_summary_title"}}
          </h2>
  
          <ul class="form-kit__errors-summary-list">
            {{#each-in @errors as |name error|}}
              <li>
                <a
                  rel="noopener noreferrer"
                  href={{concat "#control-" (this.normalizeName name)}}
                >{{error.title}}</a>:
                {{this.concatErrors error.messages}}
              </li>
            {{/each-in}}
          </ul>
        </div>
      {{/if}}
    
  */
  {
    "id": "tZNw1On8",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasErrors\"]],[[[1,\"      \"],[11,0],[24,0,\"form-kit__errors-summary\"],[24,\"aria-live\",\"assertive\"],[17,1],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"form-kit__errors-summary-title\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[1,\"\\n          \"],[1,[28,[32,1],[\"form_kit.errors_summary_title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,\"ul\"],[14,0,\"form-kit__errors-summary-list\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[30,2]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[10,3],[14,\"rel\",\"noopener noreferrer\"],[15,6,[28,[32,2],[\"#control-\",[28,[30,0,[\"normalizeName\"]],[[30,4]],null]],null]],[12],[1,[30,3,[\"title\"]]],[13],[1,\":\\n              \"],[1,[28,[30,0,[\"concatErrors\"]],[[30,3,[\"messages\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\",\"@errors\",\"error\",\"name\"],false,[\"if\",\"each\",\"-each-in\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/errors-summary.js",
    "scope": () => [icon, i18n, concat],
    "isStrictMode": true
  }), this))();
}