import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.isLoading}}
  {{loading-spinner size="small"}}
{{else}}
  <div class="ace" ...attributes>{{this.content}}</div>
{{/if}}
*/
{
  "id": "VDEBW2iM",
  "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"ace\"],[17,1],[12],[1,[30,0,[\"content\"]]],[13],[1,\"\\n\"]],[]]]],[\"&attrs\"],false,[\"if\",\"loading-spinner\"]]",
  "moduleName": "discourse/components/ace-editor.hbs",
  "isStrictMode": false
});