import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const GroupActivityFilter = setComponentTemplate(createTemplateFactory(
/*
  
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>

*/
{
  "id": "dWNaiuNL",
  "block": "[[[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@query\"],[[28,[32,1],[\"group.activity.\",[30,1]],null],[28,[32,2],null,[[\"category_id\"],[[30,2]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[32,3],[[28,[32,1],[\"groups.\",[30,1]],null]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@filter\",\"@categoryId\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/group-activity-filter.js",
  "scope": () => [LinkTo, concat, hash, i18n],
  "isStrictMode": true
}), templateOnly());
export default GroupActivityFilter;