import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { eq } from "truth-helpers";
import FKLabel from "discourse/form-kit/components/fk/label";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlCheckbox extends Component {
  static controlType = "checkbox";
  handleInput() {
    this.args.field.set(!this.args.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <FKLabel class="form-kit__control-checkbox-label">
        <input
          type="checkbox"
          checked={{eq @value true}}
          class="form-kit__control-checkbox"
          disabled={{@field.disabled}}
          ...attributes
          {{on "change" this.handleInput}}
        />
        <span>{{yield}}</span>
      </FKLabel>
    
  */
  {
    "id": "D9PM2m4Y",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-checkbox-label\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[11,\"input\"],[24,4,\"checkbox\"],[16,\"checked\",[28,[32,1],[[30,1],true],null]],[24,0,\"form-kit__control-checkbox\"],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,3],[4,[32,2],[\"change\",[30,0,[\"handleInput\"]]],null],[12],[13],[1,\"\\n      \"],[10,1],[12],[18,4,null],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@value\",\"@field\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/checkbox.js",
    "scope": () => [FKLabel, eq, on],
    "isStrictMode": true
  }), this))();
}