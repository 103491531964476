import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKControlMenuContainer = setComponentTemplate(createTemplateFactory(
/*
  
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>

*/
{
  "id": "TKNpZ7dB",
  "block": "[[[1,\"\\n  \"],[10,\"li\"],[14,0,\"form-kit__control-menu-container\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/menu/container.js",
  "isStrictMode": true
}), templateOnly());
export default FKControlMenuContainer;