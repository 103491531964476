import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKLabel = setComponentTemplate(createTemplateFactory(
/*
  
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>

*/
{
  "id": "/t8VX6sR",
  "block": "[[[1,\"\\n  \"],[11,\"label\"],[16,\"for\",[30,1]],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@fieldId\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/label.js",
  "isStrictMode": true
}), templateOnly());
export default FKLabel;