import Component from "@glimmer/component";
import { eq } from "truth-helpers";
import { NO_VALUE_OPTION } from "discourse/form-kit/lib/constants";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlSelectOption extends Component {
  get value() {
    return typeof this.args.value === "undefined" ? NO_VALUE_OPTION : this.args.value;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{! https://github.com/emberjs/ember.js/issues/19115 }}
      {{#if (eq @selected @value)}}
        <option
          class="form-kit__control-option --selected"
          value={{this.value}}
          selected
          ...attributes
        >
          {{yield}}
        </option>
      {{else}}
        <option
          class="form-kit__control-option"
          value={{this.value}}
          ...attributes
        >
          {{yield}}
        </option>
      {{/if}}
    
  */
  {
    "id": "o5MLz0ou",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[30,2]],null],[[[1,\"      \"],[11,\"option\"],[24,0,\"form-kit__control-option --selected\"],[16,2,[30,0,[\"value\"]]],[24,\"selected\",\"\"],[17,3],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,\"option\"],[24,0,\"form-kit__control-option\"],[16,2,[30,0,[\"value\"]]],[17,3],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@selected\",\"@value\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/select/option.js",
    "scope": () => [eq],
    "isStrictMode": true
  }), this))();
}