import EmberObject from "@ember/object";
import { Promise } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import { url } from "discourse/lib/computed";
import UserAction from "discourse/models/user-action";
export default class UserPostsStream extends EmberObject {
  loaded = false;
  itemsLoaded = 0;
  canLoadMore = true;
  content = [];
  static #_ = (() => dt7948.g(this.prototype, "url", [url("user.username_lower", "filter", "itemsLoaded", "/posts/%@/%@?offset=%@")]))();
  #url = (() => (dt7948.i(this, "url"), void 0))();
  filterBy(opts) {
    if (this.loaded && this.filter === opts.filter) {
      return Promise.resolve();
    }
    this.setProperties(Object.assign({
      itemsLoaded: 0,
      content: [],
      lastLoadedUrl: null
    }, opts));
    return this.findItems();
  }
  findItems() {
    if (this.loading || !this.canLoadMore) {
      return Promise.reject();
    }
    this.set("loading", true);
    return ajax(this.url).then(result => {
      if (result) {
        const posts = result.map(post => UserAction.create(post));
        this.content.pushObjects(posts);
        this.setProperties({
          loaded: true,
          itemsLoaded: this.itemsLoaded + posts.length,
          canLoadMore: posts.length > 0
        });
      }
    }).finally(() => this.set("loading", false));
  }
}