import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { or } from "truth-helpers";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import i18n from "discourse-common/helpers/i18n";
import Notifications from "./user-dropdown/notifications";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserDropdown extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  click(e1) {
    if (wantsNewWindow(e1)) {
      return;
    }
    e1.preventDefault();
    this.args.toggleUserMenu();
    // remove the focus of the header dropdown button after clicking
    e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
  }
  static #_2 = (() => dt7948.n(this.prototype, "click", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <li
        id="current-user"
        class={{concatClass
          (if @active "active")
          "header-dropdown-toggle current-user user-menu-panel"
        }}
      >
        <PluginOutlet @name="user-dropdown-button__before" />
        <button
          id="toggle-current-user"
          class="icon btn-flat"
          aria-haspopup="true"
          aria-expanded={{@active}}
          aria-label={{i18n
            "user.account_possessive"
            name=(or this.currentUser.name this.currentUser.username)
          }}
          {{on "click" this.click}}
        >
          <Notifications @active={{@active}} />
        </button>
        <PluginOutlet @name="user-dropdown-button__after" />
      </li>
    
  */
  {
    "id": "VzqJrcgf",
    "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,1,\"current-user\"],[15,0,[28,[32,0],[[52,[30,1],\"active\"],\"header-dropdown-toggle current-user user-menu-panel\"],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@name\"],[\"user-dropdown-button__before\"]],null],[1,\"\\n      \"],[11,\"button\"],[24,1,\"toggle-current-user\"],[24,0,\"icon btn-flat\"],[24,\"aria-haspopup\",\"true\"],[16,\"aria-expanded\",[30,1]],[16,\"aria-label\",[28,[32,2],[\"user.account_possessive\"],[[\"name\"],[[28,[32,3],[[30,0,[\"currentUser\",\"name\"]],[30,0,[\"currentUser\",\"username\"]]],null]]]]],[4,[32,4],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n        \"],[8,[32,5],null,[[\"@active\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,1],null,[[\"@name\"],[\"user-dropdown-button__after\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@active\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/user-dropdown.js",
    "scope": () => [concatClass, PluginOutlet, i18n, or, on, Notifications],
    "isStrictMode": true
  }), this))();
}