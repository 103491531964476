import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { NO_VALUE_OPTION } from "discourse/form-kit/lib/constants";
import FKControlSelectOption from "./select/option";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlSelect extends Component {
  static controlType = "select";
  handleInput(event1) {
    // if an option has no value, event.target.value will be the content of the option
    // this is why we use this magic value to represent no value
    this.args.field.set(event1.target.value === NO_VALUE_OPTION ? undefined : event1.target.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <select
        value={{@value}}
        disabled={{@field.disabled}}
        ...attributes
        class="form-kit__control-select"
        {{on "input" this.handleInput}}
      >
        {{yield (hash Option=(component FKControlSelectOption selected=@value))}}
      </select>
    
  */
  {
    "id": "qZfTHmQk",
    "block": "[[[1,\"\\n    \"],[11,\"select\"],[16,2,[30,1]],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,3],[24,0,\"form-kit__control-select\"],[4,[32,0],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[1,\"\\n      \"],[18,4,[[28,[32,1],null,[[\"Option\"],[[50,[32,2],0,null,[[\"selected\"],[[30,1]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@value\",\"@field\",\"&attrs\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/select.js",
    "scope": () => [on, hash, FKControlSelectOption],
    "isStrictMode": true
  }), this))();
}