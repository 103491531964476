import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{action "enterTop"}}
  @translatedAriaLabel={{i18n
    "topic_entrance.sr_jump_top_button"
    date=this.topDate
  }}
  title={{i18n "topic_entrance.jump_top_button_title"}}
  class="btn-default full jump-top"
>
  {{d-icon "step-backward"}}
  {{html-safe this.topDate}}
</DButton>

<DButton
  @action={{action "enterBottom"}}
  @translatedAriaLabel={{i18n
    "topic_entrance.sr_jump_bottom_button"
    date=this.bottomDate
  }}
  title={{i18n "topic_entrance.jump_bottom_button_title"}}
  class="btn-default full jump-bottom"
>
  {{html-safe this.bottomDate}}
  {{d-icon "step-forward"}}
</DButton>
*/
{
  "id": "PDnWqb95",
  "block": "[[[8,[39,0],[[16,\"title\",[28,[37,1],[\"topic_entrance.jump_top_button_title\"],null]],[24,0,\"btn-default full jump-top\"]],[[\"@action\",\"@translatedAriaLabel\"],[[28,[37,2],[[30,0],\"enterTop\"],null],[28,[37,1],[\"topic_entrance.sr_jump_top_button\"],[[\"date\"],[[30,0,[\"topDate\"]]]]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,3],[\"step-backward\"],null]],[1,\"\\n  \"],[1,[28,[35,4],[[30,0,[\"topDate\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[16,\"title\",[28,[37,1],[\"topic_entrance.jump_bottom_button_title\"],null]],[24,0,\"btn-default full jump-bottom\"]],[[\"@action\",\"@translatedAriaLabel\"],[[28,[37,2],[[30,0],\"enterBottom\"],null],[28,[37,1],[\"topic_entrance.sr_jump_bottom_button\"],[[\"date\"],[[30,0,[\"bottomDate\"]]]]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,4],[[30,0,[\"bottomDate\"]]],null]],[1,\"\\n  \"],[1,[28,[35,3],[\"step-forward\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-button\",\"i18n\",\"action\",\"d-icon\",\"html-safe\"]]",
  "moduleName": "discourse/components/topic-entrance.hbs",
  "isStrictMode": false
});