/* import __COLOCATED_TEMPLATE__ from './topic-bulk-actions.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { Promise } from "rsvp";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";
import AppendTags from "../bulk-actions/append-tags";
import ChangeCategory from "../bulk-actions/change-category";
import ChangeTags from "../bulk-actions/change-tags";
import NotificationLevel from "../bulk-actions/notification-level";
import TEMPLATE from "./topic-bulk-actions.hbs";
import { setComponentTemplate } from "@ember/component";
const _customButtons = [];
export function _addBulkButton(opts) {
  _customButtons.push({
    label: opts.label,
    icon: opts.icon,
    class: opts.class,
    visible: opts.visible,
    action: opts.action
  });
}
export function clearBulkButtons() {
  _customButtons.length = 0;
}

// Modal for performing bulk actions on topics
export default class TopicBulkActions extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "dialog", [service]))();
  #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "loading", [tracked], function () {
    return false;
  }))();
  #loading = (() => (dt7948.i(this, "loading"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "showProgress", [tracked], function () {
    return false;
  }))();
  #showProgress = (() => (dt7948.i(this, "showProgress"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "processedTopicCount", [tracked], function () {
    return 0;
  }))();
  #processedTopicCount = (() => (dt7948.i(this, "processedTopicCount"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "activeComponent", [tracked], function () {
    return null;
  }))();
  #activeComponent = (() => (dt7948.i(this, "activeComponent"), void 0))();
  defaultButtons = [{
    label: "topics.bulk.change_category",
    icon: "pencil-alt",
    class: "btn-default bulk-actions__change-category",
    visible: _ref => {
      let {
        topics
      } = _ref;
      return !topics.some(t => t.isPrivateMessage);
    },
    action(_ref2) {
      let {
        setComponent
      } = _ref2;
      setComponent(ChangeCategory);
    }
  }, {
    label: "topics.bulk.close_topics",
    icon: "lock",
    class: "btn-default bulk-actions__close-topics",
    visible: _ref3 => {
      let {
        topics
      } = _ref3;
      return !topics.some(t => t.isPrivateMessage);
    },
    action(_ref4) {
      let {
        forEachPerformed
      } = _ref4;
      forEachPerformed({
        type: "close"
      }, t => t.set("closed", true));
    }
  }, {
    label: "topics.bulk.archive_topics",
    icon: "folder",
    class: "btn-default bulk-actions__archive-topics",
    visible: _ref5 => {
      let {
        topics
      } = _ref5;
      return !topics.some(t => t.isPrivateMessage);
    },
    action(_ref6) {
      let {
        forEachPerformed
      } = _ref6;
      forEachPerformed({
        type: "archive"
      }, t => t.set("archived", true));
    }
  }, {
    label: "topics.bulk.archive_topics",
    icon: "folder",
    class: "btn-default bulk-actions__archive-topics",
    visible: _ref7 => {
      let {
        topics
      } = _ref7;
      return topics.some(t => t.isPrivateMessage);
    },
    action: _ref8 => {
      let {
        performAndRefresh
      } = _ref8;
      const userPrivateMessages = getOwner(this).lookup("controller:user-private-messages");
      let params = {
        type: "archive_messages"
      };
      if (userPrivateMessages.isGroup) {
        params.group = userPrivateMessages.groupFilter;
      }
      performAndRefresh(params);
    }
  }, {
    label: "topics.bulk.move_messages_to_inbox",
    icon: "folder",
    class: "btn-default bulk-actions__move-messages-to-inbox",
    visible: _ref9 => {
      let {
        topics
      } = _ref9;
      return topics.some(t => t.isPrivateMessage);
    },
    action: _ref10 => {
      let {
        performAndRefresh
      } = _ref10;
      const userPrivateMessages = getOwner(this).lookup("controller:user-private-messages");
      let params = {
        type: "move_messages_to_inbox"
      };
      if (userPrivateMessages.isGroup) {
        params.group = userPrivateMessages.groupFilter;
      }
      performAndRefresh(params);
    }
  }, {
    label: "topics.bulk.notification_level",
    icon: "d-regular",
    class: "btn-default bulk-actions__notification-level",
    action(_ref11) {
      let {
        setComponent
      } = _ref11;
      setComponent(NotificationLevel);
    }
  }, {
    label: "topics.bulk.defer",
    icon: "circle",
    class: "btn-default bulk-actions__defer",
    visible: _ref12 => {
      let {
        currentUser
      } = _ref12;
      return currentUser.user_option.enable_defer;
    },
    action(_ref13) {
      let {
        performAndRefresh
      } = _ref13;
      performAndRefresh({
        type: "destroy_post_timing"
      });
    }
  }, {
    label: "topics.bulk.unlist_topics",
    icon: "far-eye-slash",
    class: "btn-default bulk-actions__unlist",
    visible: _ref14 => {
      let {
        topics
      } = _ref14;
      return topics.some(t => t.visible) && !topics.some(t => t.isPrivateMessage);
    },
    action(_ref15) {
      let {
        forEachPerformed
      } = _ref15;
      forEachPerformed({
        type: "unlist"
      }, t => t.set("visible", false));
    }
  }, {
    label: "topics.bulk.relist_topics",
    icon: "far-eye",
    class: "btn-default bulk-actions__relist",
    visible: _ref16 => {
      let {
        topics
      } = _ref16;
      return topics.some(t => !t.visible) && !topics.some(t => t.isPrivateMessage);
    },
    action(_ref17) {
      let {
        forEachPerformed
      } = _ref17;
      forEachPerformed({
        type: "relist"
      }, t => t.set("visible", true));
    }
  }, {
    label: "topics.bulk.reset_bump_dates",
    icon: "anchor",
    class: "btn-default bulk-actions__reset-bump-dates",
    visible: _ref18 => {
      let {
        currentUser
      } = _ref18;
      return currentUser.canManageTopic;
    },
    action(_ref19) {
      let {
        performAndRefresh
      } = _ref19;
      performAndRefresh({
        type: "reset_bump_dates"
      });
    }
  }, {
    label: "topics.bulk.change_tags",
    icon: "tag",
    class: "btn-default bulk-actions__change-tags",
    visible: _ref20 => {
      let {
        currentUser,
        siteSettings
      } = _ref20;
      return siteSettings.tagging_enabled && currentUser.canManageTopic;
    },
    action(_ref21) {
      let {
        setComponent
      } = _ref21;
      setComponent(ChangeTags);
    }
  }, {
    label: "topics.bulk.append_tags",
    icon: "tag",
    class: "btn-default bulk-actions__append-tags",
    visible: _ref22 => {
      let {
        currentUser,
        siteSettings
      } = _ref22;
      return siteSettings.tagging_enabled && currentUser.canManageTopic;
    },
    action(_ref23) {
      let {
        setComponent
      } = _ref23;
      setComponent(AppendTags);
    }
  }, {
    label: "topics.bulk.remove_tags",
    icon: "tag",
    class: "btn-default bulk-actions__remove-tags",
    visible: _ref24 => {
      let {
        currentUser,
        siteSettings
      } = _ref24;
      return siteSettings.tagging_enabled && currentUser.canManageTopic;
    },
    action: _ref25 => {
      let {
        performAndRefresh,
        topics
      } = _ref25;
      this.dialog.deleteConfirm({
        message: I18n.t("topics.bulk.confirm_remove_tags", {
          count: topics.length
        }),
        didConfirm: () => performAndRefresh({
          type: "remove_tags"
        })
      });
    }
  }, {
    label: "topics.bulk.delete",
    icon: "trash-alt",
    class: "btn-danger delete-topics bulk-actions__delete",
    visible: _ref26 => {
      let {
        currentUser
      } = _ref26;
      return currentUser.staff;
    },
    action(_ref27) {
      let {
        performAndRefresh
      } = _ref27;
      performAndRefresh({
        type: "delete"
      });
    }
  }];
  constructor() {
    super(...arguments);
    if (this.args.model.initialAction === "set-component") {
      this.setComponent(this.args.model.initialComponent);
    }
  }
  get buttons() {
    return [...this.defaultButtons, ..._customButtons].filter(_ref28 => {
      let {
        visible
      } = _ref28;
      if (visible) {
        return visible({
          topics: this.args.model.topics,
          category: this.args.model.category,
          currentUser: this.currentUser,
          siteSettings: this.siteSettings
        });
      } else {
        return true;
      }
    });
  }
  async perform(operation) {
    this.loading = true;
    if (this.args.model.topics.length > 20) {
      this.showProgress = true;
    }
    try {
      return this._processChunks(operation);
    } catch {
      this.dialog.alert(I18n.t("generic_error"));
    } finally {
      this.loading = false;
      this.processedTopicCount = 0;
      this.showProgress = false;
    }
  }
  _generateTopicChunks(allTopics) {
    let startIndex = 0;
    const chunkSize = 30;
    const chunks = [];
    while (startIndex < allTopics.length) {
      const topics = allTopics.slice(startIndex, startIndex + chunkSize);
      chunks.push(topics);
      startIndex += chunkSize;
    }
    return chunks;
  }
  _processChunks(operation) {
    const allTopics = this.args.model.topics;
    const topicChunks = this._generateTopicChunks(allTopics);
    const topicIds = [];
    const tasks = topicChunks.map(topics => async () => {
      const result = await Topic.bulkOperation(topics, operation);
      this.processedTopicCount = this.processedTopicCount + topics.length;
      return result;
    });
    return new Promise((resolve, reject) => {
      const resolveNextTask = async () => {
        if (tasks.length === 0) {
          const topics = topicIds.map(id => allTopics.findBy("id", id));
          return resolve(topics);
        }
        const task = tasks.shift();
        try {
          const result = await task();
          if (result?.topic_ids) {
            topicIds.push(...result.topic_ids);
          }
          resolveNextTask();
        } catch {
          reject();
        }
      };
      resolveNextTask();
    });
  }
  setComponent(component) {
    this.activeComponent = component;
  }
  static #_8 = (() => dt7948.n(this.prototype, "setComponent", [action]))();
  async forEachPerformed(operation, cb) {
    const topics = await this.perform(operation);
    if (topics) {
      topics.forEach(cb);
      this.args.model.refreshClosure?.();
      this.args.closeModal();
    }
  }
  static #_9 = (() => dt7948.n(this.prototype, "forEachPerformed", [action]))();
  async performAndRefresh(operation) {
    await this.perform(operation);
    this.args.model.refreshClosure?.();
    this.args.closeModal();
  }
  static #_10 = (() => dt7948.n(this.prototype, "performAndRefresh", [action]))();
}
setComponentTemplate(TEMPLATE, TopicBulkActions);