import { fn } from "@ember/helper";
import { on } from "@ember/modifier";
import { eq } from "truth-helpers";
import FKLabel from "discourse/form-kit/components/fk/label";
import uniqueId from "discourse/helpers/unique-id";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const FKControlConditionalContentOption = setComponentTemplate(createTemplateFactory(
/*
  
  {{#let (uniqueId) as |uuid|}}
    <FKLabel @fieldId={{uuid}} class="form-kit__control-radio-label">
      <input
        type="radio"
        id={{uuid}}
        value={{@name}}
        checked={{eq @name @activeName}}
        class="form-kit__control-radio"
        {{on "change" (fn @setCondition @name)}}
      />

      <span>{{yield}}</span>
    </FKLabel>
  {{/let}}

*/
{
  "id": "KjqHsv7p",
  "block": "[[[1,\"\\n\"],[44,[[28,[32,0],null,null]],[[[1,\"    \"],[8,[32,1],[[24,0,\"form-kit__control-radio-label\"]],[[\"@fieldId\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[11,\"input\"],[16,1,[30,1]],[16,2,[30,2]],[16,\"checked\",[28,[32,2],[[30,2],[30,3]],null]],[24,0,\"form-kit__control-radio\"],[24,4,\"radio\"],[4,[32,3],[\"change\",[28,[32,4],[[30,4],[30,2]],null]],null],[12],[13],[1,\"\\n\\n      \"],[10,1],[12],[18,5,null],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"uuid\",\"@name\",\"@activeName\",\"@setCondition\",\"&default\"],false,[\"let\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/conditional-content/condition.js",
  "scope": () => [uniqueId, FKLabel, eq, on, fn],
  "isStrictMode": true
}), templateOnly());
export default FKControlConditionalContentOption;