import Component from "@glimmer/component";
import BadgeButton from "discourse/components/badge-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class UserBadge extends Component {
  get showGrantCount() {
    return this.args.count > 1;
  }
  get badgeUrl() {
    // NOTE: I tried using a link-to helper here but the queryParams mean it fails
    let username1 = this.args.user?.username_lower;
    username1 = username1 ? `?username=${username1}` : "";
    return this.args.badge.url + username1;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <a class="user-card-badge-link" href={{this.badgeUrl}}>
        <BadgeButton @badge={{@badge}}>
          {{#if this.showGrantCount}}
            <span class="count">&nbsp;(&times;{{@count}})</span>
          {{/if}}
        </BadgeButton>
      </a>
    
  */
  {
    "id": "+LhccFIU",
    "block": "[[[1,\"\\n    \"],[10,3],[14,0,\"user-card-badge-link\"],[15,6,[30,0,[\"badgeUrl\"]]],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@badge\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showGrantCount\"]],[[[1,\"          \"],[10,1],[14,0,\"count\"],[12],[1,\" (×\"],[1,[30,2]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@badge\",\"@count\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-badge.js",
    "scope": () => [BadgeButton],
    "isStrictMode": true
  }), this))();
}