import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { popupAjaxError } from "discourse/lib/ajax-error";
import User from "discourse/models/user";
import i18n from "discourse-common/helpers/i18n";
import IgnoredUserListItem from "./ignored-user-list-item";
import IgnoreDurationModal from "./modal/ignore-duration-with-username";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class IgnoredUserList extends Component {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  async removeIgnoredUser(item1) {
    this.args.items.removeObject(item1);
    try {
      const user1 = await User.findByUsername(item1);
      await user1.updateNotificationLevel({
        level: "normal",
        actingUser: this.args.model
      });
    } catch (e1) {
      popupAjaxError(e1);
    }
  }
  static #_2 = (() => dt7948.n(this.prototype, "removeIgnoredUser", [action]))();
  newIgnoredUser() {
    this.modal.show(IgnoreDurationModal, {
      model: {
        actingUser: this.args.model,
        ignoredUsername: null,
        onUserIgnored: username1 => {
          this.args.items.addObject(username1);
        }
      }
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "newIgnoredUser", [action]))();
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div>
        <div class="ignored-list">
          {{#each @items as |item|}}
            <IgnoredUserListItem
              @item={{item}}
              @onRemoveIgnoredUser={{this.removeIgnoredUser}}
            />
          {{else}}
            {{i18n "user.user_notifications.ignore_no_users"}}
          {{/each}}
        </div>
        <div class="instructions">{{i18n "user.ignored_users_instructions"}}</div>
        <div>
          <DButton
            @action={{this.newIgnoredUser}}
            @icon="plus"
            @label="user.user_notifications.add_ignored_user"
            class="btn-default"
          />
        </div>
      </div>
    
  */
  {
    "id": "jTcnqLwG",
    "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"ignored-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"          \"],[8,[32,0],null,[[\"@item\",\"@onRemoveIgnoredUser\"],[[30,2],[30,0,[\"removeIgnoredUser\"]]]],null],[1,\"\\n\"]],[2]],[[[1,\"          \"],[1,[28,[32,1],[\"user.user_notifications.ignore_no_users\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[32,1],[\"user.ignored_users_instructions\"],null]],[13],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"newIgnoredUser\"]],\"plus\",\"user.user_notifications.add_ignored_user\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@items\",\"item\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/ignored-user-list.js",
    "scope": () => [IgnoredUserListItem, i18n, DButton],
    "isStrictMode": true
  }), this))();
}