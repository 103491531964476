import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import { escapeExpression } from "discourse/lib/utilities";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlTextarea extends Component {
  static controlType = "textarea";
  handleInput(event1) {
    this.args.field.set(event1.target.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  get style() {
    if (!this.args.height) {
      return;
    }
    return `height: ${htmlSafe(escapeExpression(this.args.height) + "px")}`;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <textarea
        class="form-kit__control-textarea"
        style={{this.style}}
        ...attributes
        {{on "input" this.handleInput}}
      >{{@value}}</textarea>
    
  */
  {
    "id": "WZ5p5EFp",
    "block": "[[[1,\"\\n    \"],[11,\"textarea\"],[24,0,\"form-kit__control-textarea\"],[16,5,[30,0,[\"style\"]]],[17,1],[4,[32,0],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[1,[30,2]],[13],[1,\"\\n  \"]],[\"&attrs\",\"@value\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/textarea.js",
    "scope": () => [on],
    "isStrictMode": true
  }), this))();
}