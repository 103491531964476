import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import FKField from "discourse/form-kit/components/fk/field";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKCollection extends Component {
  remove(index1) {
    this.args.remove(this.args.name, index1);
  }
  static #_ = (() => dt7948.n(this.prototype, "remove", [action]))();
  get collectionValue() {
    return this.args.data.get(this.args.name);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="form-kit__collection">
        {{#each this.collectionValue key="index" as |data index|}}
          {{yield
            (hash
              Field=(component
                FKField
                errors=@errors
                collectionName=@name
                collectionIndex=index
                addError=@addError
                data=@data
                set=@set
                registerField=@registerField
                unregisterField=@unregisterField
                triggerRevalidationFor=@triggerRevalidationFor
              )
              remove=this.remove
            )
            index
          }}
        {{/each}}
      </div>
    
  */
  {
    "id": "WZzf6p59",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"form-kit__collection\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"collectionValue\"]]],null]],null],\"index\",[[[1,\"        \"],[18,11,[[28,[32,0],null,[[\"Field\",\"remove\"],[[50,[32,1],0,null,[[\"errors\",\"collectionName\",\"collectionIndex\",\"addError\",\"data\",\"set\",\"registerField\",\"unregisterField\",\"triggerRevalidationFor\"],[[30,3],[30,4],[30,2],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10]]]],[30,0,[\"remove\"]]]]],[30,2]]],[1,\"\\n\"]],[1,2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"data\",\"index\",\"@errors\",\"@name\",\"@addError\",\"@data\",\"@set\",\"@registerField\",\"@unregisterField\",\"@triggerRevalidationFor\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/collection.js",
    "scope": () => [hash, FKField],
    "isStrictMode": true
  }), this))();
}