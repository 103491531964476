import UserLink from "discourse/components/user-link";
import avatar from "discourse/helpers/avatar";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ReviewableCreatedBy = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="created-by">
    {{#if @user}}
      <UserLink @user={{@user}}>{{avatar @user imageSize="large"}}</UserLink>
    {{else}}
      {{icon "far-trash-alt" class="deleted-user-avatar"}}
    {{/if}}
  </div>

*/
{
  "id": "t77I9Lrh",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"created-by\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,[28,[32,1],[[30,1]],[[\"imageSize\"],[\"large\"]]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[32,2],[\"far-trash-alt\"],[[\"class\"],[\"deleted-user-avatar\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/reviewable-created-by.js",
  "scope": () => [UserLink, avatar, icon],
  "isStrictMode": true
}), templateOnly());
export default ReviewableCreatedBy;