import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKErrors extends Component {
  concatErrors(errors1) {
    return errors1.join(", ");
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
        <span>
          {{icon "exclamation-triangle"}}
          {{this.concatErrors @error.messages}}
        </span>
      </p>
    
  */
  {
    "id": "6Di3ouwO",
    "block": "[[[1,\"\\n    \"],[11,2],[24,0,\"form-kit__errors\"],[16,1,[30,1]],[24,\"aria-live\",\"assertive\"],[17,2],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"exclamation-triangle\"],null]],[1,\"\\n        \"],[1,[28,[30,0,[\"concatErrors\"]],[[30,3,[\"messages\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@id\",\"&attrs\",\"@error\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/errors.js",
    "scope": () => [icon],
    "isStrictMode": true
  }), this))();
}