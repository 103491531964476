import EmberObject from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default EmberObject.extend(dt7948.p({
  tagName: "td",
  ratio(likeCount, postCount) {
    const likes = parseFloat(likeCount);
    const posts = parseFloat(postCount);
    if (posts < 10) {
      return 0;
    }
    return (likes || 0) / posts;
  },
  title(count, ratio) {
    return I18n.messageFormat("posts_likes_MF", {
      count,
      ratio
    });
  },
  ratioText(ratio) {
    const settings = this.siteSettings;
    if (ratio > settings.topic_post_like_heat_high) {
      return "high";
    }
    if (ratio > settings.topic_post_like_heat_medium) {
      return "med";
    }
    if (ratio > settings.topic_post_like_heat_low) {
      return "low";
    }
    return "";
  },
  likesHeat(ratioText) {
    if (ratioText && ratioText.length) {
      return `heatmap-${ratioText}`;
    }
  }
}, [["method", "ratio", [discourseComputed("topic.like_count", "topic.posts_count")]], ["method", "title", [discourseComputed("topic.replyCount", "ratioText")]], ["method", "ratioText", [discourseComputed("ratio")]], ["method", "likesHeat", [discourseComputed("ratioText")]]]));