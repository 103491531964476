import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import DEditor from "discourse/components/d-editor";
import { escapeExpression } from "discourse/lib/utilities";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlComposer extends Component {
  static controlType = "composer";
  handleInput(event1) {
    this.args.field.set(event1.target.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  get style() {
    if (this.args.height) {
      return;
    }
    return `height: ${htmlSafe(escapeExpression(this.args.height) + "px")}`;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DEditor
        @value={{readonly @value}}
        @change={{this.handleInput}}
        @disabled={{@field.disabled}}
        class="form-kit__control-composer"
        style={{this.style}}
        @textAreaId={{@field.id}}
      />
    
  */
  {
    "id": "D9ChqDWX",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-composer\"],[16,5,[30,0,[\"style\"]]]],[[\"@value\",\"@change\",\"@disabled\",\"@textAreaId\"],[[28,[31,0],[[30,1]],null],[30,0,[\"handleInput\"]],[30,2,[\"disabled\"]],[30,2,[\"id\"]]]],null],[1,\"\\n  \"]],[\"@value\",\"@field\"],false,[\"readonly\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/composer.js",
    "scope": () => [DEditor],
    "isStrictMode": true
  }), this))();
}