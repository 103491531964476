import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { eq } from "truth-helpers";
import FKLabel from "discourse/form-kit/components/fk/label";
import uniqueId from "discourse/helpers/unique-id";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlQuestion extends Component {
  static controlType = "question";
  handleInput(event1) {
    this.args.field.set(event1.target.value === "true");
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="form-kit__inline-radio">
        {{#let (uniqueId) as |uuid|}}
          <FKLabel @fieldId={{uuid}} class="form-kit__control-radio-label --yes">
            <input
              name={{@field.name}}
              type="radio"
              value="true"
              checked={{eq @value true}}
              class="form-kit__control-radio"
              disabled={{@field.disabled}}
              ...attributes
              id={{uuid}}
              {{on "change" this.handleInput}}
            />
  
            {{#if @yesLabel}}
              {{@yesLabel}}
            {{else}}
              {{i18n "yes_value"}}
            {{/if}}
          </FKLabel>
        {{/let}}
  
        {{#let (uniqueId) as |uuid|}}
          <FKLabel @fieldId={{uuid}} class="form-kit__control-radio-label --no">
            <input
              name={{@field.name}}
              type="radio"
              value="false"
              checked={{eq @value false}}
              class="form-kit__control-radio"
              disabled={{@field.disabled}}
              ...attributes
              id={{uuid}}
              {{on "change" this.handleInput}}
            />
  
            {{#if @noLabel}}
              {{@noLabel}}
            {{else}}
              {{i18n "no_value"}}
            {{/if}}
          </FKLabel>
        {{/let}}
      </div>
    
  */
  {
    "id": "ThWXs9vX",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"form-kit__inline-radio\"],[12],[1,\"\\n\"],[44,[[28,[32,0],null,null]],[[[1,\"        \"],[8,[32,1],[[24,0,\"form-kit__control-radio-label --yes\"]],[[\"@fieldId\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[11,\"input\"],[16,3,[30,2,[\"name\"]]],[24,4,\"radio\"],[24,2,\"true\"],[16,\"checked\",[28,[32,2],[[30,3],true],null]],[24,0,\"form-kit__control-radio\"],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,4],[16,1,[30,1]],[4,[32,3],[\"change\",[30,0,[\"handleInput\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"            \"],[1,[30,5]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,4],[\"yes_value\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[1]]],[1,\"\\n\"],[44,[[28,[32,0],null,null]],[[[1,\"        \"],[8,[32,1],[[24,0,\"form-kit__control-radio-label --no\"]],[[\"@fieldId\"],[[30,6]]],[[\"default\"],[[[[1,\"\\n          \"],[11,\"input\"],[16,3,[30,2,[\"name\"]]],[24,4,\"radio\"],[24,2,\"false\"],[16,\"checked\",[28,[32,2],[[30,3],false],null]],[24,0,\"form-kit__control-radio\"],[16,\"disabled\",[30,2,[\"disabled\"]]],[17,4],[16,1,[30,6]],[4,[32,3],[\"change\",[30,0,[\"handleInput\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,7],[[[1,\"            \"],[1,[30,7]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,4],[\"no_value\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[6]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"uuid\",\"@field\",\"@value\",\"&attrs\",\"@yesLabel\",\"uuid\",\"@noLabel\"],false,[\"let\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/question.js",
    "scope": () => [uniqueId, FKLabel, eq, on, i18n],
    "isStrictMode": true
  }), this))();
}