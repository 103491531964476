import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="flagged-post-header">
  <ReviewableTopicLink @reviewable={{@reviewable}} @tagName="" />
  <ReviewablePostEdits @reviewable={{@reviewable}} @tagName="" />
</div>

<div class="post-contents-wrapper">
  <ReviewableCreatedBy @user={{@reviewable.target_created_by}} />
  <div class="post-contents">
    <ReviewablePostHeader
      @reviewable={{@reviewable}}
      @createdBy={{@reviewable.target_created_by}}
      @tagName=""
    />
    <div
      class="post-body {{if this.isCollapsed 'is-collapsed'}}"
      {{did-insert this.calculatePostBodySize @reviewable}}
    >
      {{#if @reviewable.blank_post}}
        <p>{{i18n "review.deleted_post"}}</p>
      {{else}}
        {{html-safe @reviewable.cooked}}
      {{/if}}
    </div>

    {{#if this.isLongPost}}
      <DButton
        @action={{this.toggleContent}}
        @label={{this.collapseButtonProps.label}}
        @icon={{this.collapseButtonProps.icon}}
        class="btn-default btn-icon post-body__toggle-btn"
      />
    {{/if}}
    <span>
      <PluginOutlet
        @name="after-reviewable-flagged-post-body"
        @connectorTagName="div"
        @outletArgs={{hash model=@reviewable}}
      />
    </span>
    {{yield}}
  </div>
</div>
*/
{
  "id": "/wZOjdqL",
  "block": "[[[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@reviewable\",\"@tagName\"],[[30,1],\"\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@reviewable\",\"@tagName\"],[[30,1],\"\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\"],[[30,1,[\"target_created_by\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n    \"],[11,0],[16,0,[29,[\"post-body \",[52,[30,0,[\"isCollapsed\"]],\"is-collapsed\"]]]],[4,[38,5],[[30,0,[\"calculatePostBodySize\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[30,1,[\"blank_post\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,6],[\"review.deleted_post\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,7],[[30,1,[\"cooked\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isLongPost\"]],[[[1,\"      \"],[8,[39,8],[[24,0,\"btn-default btn-icon post-body__toggle-btn\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"toggleContent\"]],[30,0,[\"collapseButtonProps\",\"label\"]],[30,0,[\"collapseButtonProps\",\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,1],[12],[1,\"\\n      \"],[8,[39,9],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"after-reviewable-flagged-post-body\",\"div\",[28,[37,10],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@reviewable\",\"&default\"],false,[\"reviewable-topic-link\",\"reviewable-post-edits\",\"reviewable-created-by\",\"reviewable-post-header\",\"if\",\"did-insert\",\"i18n\",\"html-safe\",\"d-button\",\"plugin-outlet\",\"hash\",\"yield\"]]",
  "moduleName": "discourse/components/reviewable-flagged-post.hbs",
  "isStrictMode": false
});