import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import PluginOutlet from "discourse/components/plugin-outlet";
import coldAgeClass from "discourse/helpers/cold-age-class";
import concatClass from "discourse/helpers/concat-class";
import element from "discourse/helpers/element";
import formatDate from "discourse/helpers/format-date";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ActivityColumn extends Component {
  static #_ = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  get wrapperElement() {
    return element(this.args.tagName ?? "td");
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <this.wrapperElement
        title={{htmlSafe @topic.bumpedAtTitle}}
        class={{concatClass
          "activity"
          (coldAgeClass @topic.createdAt startDate=@topic.bumpedAt class="")
        }}
        ...attributes
      >
        <a
          href={{@topic.lastPostUrl}}
          class="post-activity"
        >{{! no whitespace
          }}<PluginOutlet
            @name="topic-list-before-relative-date"
            @outletArgs={{hash topic=@topic}}
          />
          {{~formatDate @topic.bumpedAt format="tiny" noTitle="true"~}}
        </a>
      </this.wrapperElement>
    
  */
  {
    "id": "h0DcaC+y",
    "block": "[[[1,\"\\n    \"],[8,[30,0,[\"wrapperElement\"]],[[16,\"title\",[28,[32,0],[[30,1,[\"bumpedAtTitle\"]]],null]],[16,0,[28,[32,1],[\"activity\",[28,[32,2],[[30,1,[\"createdAt\"]]],[[\"startDate\",\"class\"],[[30,1,[\"bumpedAt\"]],\"\"]]]],null]],[17,2]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"lastPostUrl\"]]],[14,0,\"post-activity\"],[12],[8,[32,3],null,[[\"@name\",\"@outletArgs\"],[\"topic-list-before-relative-date\",[28,[32,4],null,[[\"topic\"],[[30,1]]]]]],null],[1,[28,[32,5],[[30,1,[\"bumpedAt\"]]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@topic\",\"&attrs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/activity-column.js",
    "scope": () => [htmlSafe, concatClass, coldAgeClass, PluginOutlet, hash, formatDate],
    "isStrictMode": true
  }), this))();
}