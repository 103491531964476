import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import AceEditor from "discourse/components/ace-editor";
import { escapeExpression } from "discourse/lib/utilities";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlCode extends Component {
  static controlType = "code";
  initialValue = (() => this.args.value || "")();
  handleInput(content1) {
    this.args.field.set(content1);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  get style() {
    if (!this.args.height) {
      return;
    }
    return `height: ${htmlSafe(escapeExpression(this.args.height) + "px")}`;
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <AceEditor
        @content={{readonly this.initialValue}}
        @mode={{@lang}}
        @disabled={{@field.disabled}}
        @onChange={{this.handleInput}}
        class="form-kit__control-code"
        style={{this.style}}
        ...attributes
      />
    
  */
  {
    "id": "kSDaGCJO",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-code\"],[16,5,[30,0,[\"style\"]]],[17,1]],[[\"@content\",\"@mode\",\"@disabled\",\"@onChange\"],[[28,[31,0],[[30,0,[\"initialValue\"]]],null],[30,2],[30,3,[\"disabled\"]],[30,0,[\"handleInput\"]]]],null],[1,\"\\n  \"]],[\"&attrs\",\"@lang\",\"@field\"],false,[\"readonly\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/code.js",
    "scope": () => [AceEditor],
    "isStrictMode": true
  }), this))();
}