import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import avatar from "discourse/helpers/avatar";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class ReviewableClaimedTopic extends Component {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "store", [service]))();
  #store = (() => (dt7948.i(this, "store"), void 0))();
  get enabled() {
    return this.siteSettings.reviewable_claiming !== "disabled";
  }
  async unclaim() {
    try {
      await ajax(`/reviewable_claimed_topics/${this.args.topicId}`, {
        type: "DELETE"
      });
      this.args.onClaim(null);
    } catch (e1) {
      popupAjaxError(e1);
    }
  }
  static #_4 = (() => dt7948.n(this.prototype, "unclaim", [action]))();
  async claim() {
    const claim1 = this.store.createRecord("reviewable-claimed-topic");
    try {
      await claim1.save({
        topic_id: this.args.topicId
      });
      this.args.onClaim(this.currentUser);
    } catch (e1) {
      popupAjaxError(e1);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "claim", [action]))();
  static #_6 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.enabled}}
        <div class="reviewable-claimed-topic">
          {{#if @claimedBy}}
            <div class="claimed-by">
              {{avatar @claimedBy imageSize="small"}}
              <span class="claimed-username">{{@claimedBy.username}}</span>
            </div>
            <DButton
              @icon="times"
              @action={{this.unclaim}}
              @title="review.unclaim.help"
              class="btn-small unclaim"
            />
          {{else}}
            <DButton
              @icon="user-plus"
              @title="review.claim.title"
              @action={{this.claim}}
              class="btn-small claim"
            />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "YkJQVWck",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"enabled\"]],[[[1,\"      \"],[10,0],[14,0,\"reviewable-claimed-topic\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[10,0],[14,0,\"claimed-by\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n            \"],[10,1],[14,0,\"claimed-username\"],[12],[1,[30,1,[\"username\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-small unclaim\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,0,[\"unclaim\"]],\"review.unclaim.help\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-small claim\"]],[[\"@icon\",\"@title\",\"@action\"],[\"user-plus\",\"review.claim.title\",[30,0,[\"claim\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@claimedBy\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/reviewable-claimed-topic.js",
    "scope": () => [avatar, DButton],
    "isStrictMode": true
  }), this))();
}