import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DesktopNotificationsConfig extends Component {
  static #_ = (() => dt7948.g(this.prototype, "desktopNotifications", [service]))();
  #desktopNotifications = (() => (dt7948.i(this, "desktopNotifications"), void 0))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="controls">
        {{#if this.desktopNotifications.isNotSupported}}
          <DButton
            @icon="bell-slash"
            @label="user.desktop_notifications.not_supported"
            @disabled="true"
            class="btn-default"
          />
        {{/if}}
        {{#if this.desktopNotifications.isDeniedPermission}}
          <DButton
            @icon="bell-slash"
            @label="user.desktop_notifications.perm_denied_btn"
            @disabled="true"
            class="btn-default"
          />
          <span>
            {{i18n "user.desktop_notifications.perm_denied_expl"}}
          </span>
        {{else}}
          {{#if this.desktopNotifications.isSubscribed}}
            <DButton
              @icon="far-bell-slash"
              @label="user.desktop_notifications.disable"
              @action={{this.desktopNotifications.disable}}
              class="btn-default"
            />
          {{else}}
            <DButton
              @icon="far-bell"
              @label="user.desktop_notifications.enable"
              @action={{this.desktopNotifications.enable}}
              class="btn-default"
            />
          {{/if}}
        {{/if}}
      </div>
    
  */
  {
    "id": "T2UfeX3k",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"desktopNotifications\",\"isNotSupported\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@disabled\"],[\"bell-slash\",\"user.desktop_notifications.not_supported\",\"true\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"desktopNotifications\",\"isDeniedPermission\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@disabled\"],[\"bell-slash\",\"user.desktop_notifications.perm_denied_btn\",\"true\"]],null],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"user.desktop_notifications.perm_denied_expl\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"desktopNotifications\",\"isSubscribed\"]],[[[1,\"          \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@action\"],[\"far-bell-slash\",\"user.desktop_notifications.disable\",[30,0,[\"desktopNotifications\",\"disable\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@action\"],[\"far-bell\",\"user.desktop_notifications.enable\",[30,0,[\"desktopNotifications\",\"enable\"]]]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/desktop-notification-config.js",
    "scope": () => [DButton, i18n],
    "isStrictMode": true
  }), this))();
}