import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import DMenu from "discourse/components/d-menu";
import DropdownMenu from "discourse/components/dropdown-menu";
import FKControlMenuContainer from "discourse/form-kit/components/fk/control/menu/container";
import FKControlMenuDivider from "discourse/form-kit/components/fk/control/menu/divider";
import FKControlMenuItem from "discourse/form-kit/components/fk/control/menu/item";
import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlMenu extends Component {
  static controlType = "menu";
  static #_ = (() => dt7948.g(this.prototype, "menuApi", [tracked]))();
  #menuApi = (() => (dt7948.i(this, "menuApi"), void 0))();
  registerMenuApi(api1) {
    this.menuApi = api1;
  }
  static #_2 = (() => dt7948.n(this.prototype, "registerMenuApi", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DMenu
        @onRegisterApi={{this.registerMenuApi}}
        @triggerClass="form-kit__control-menu"
        @disabled={{@field.disabled}}
        @placement="bottom-start"
        @offset={{5}}
        id={{@field.id}}
        data-value={{@value}}
        @modalForMobile={{true}}
      >
        <:trigger>
          <span class="d-button-label">
            {{@selection}}
          </span>
          {{icon "angle-down"}}
        </:trigger>
        <:content>
          <DropdownMenu as |menu|>
            {{yield
              (hash
                Item=(component
                  FKControlMenuItem
                  item=menu.item
                  field=@field
                  menuApi=this.menuApi
                )
                Divider=(component FKControlMenuDivider divider=menu.divider)
                Container=FKControlMenuContainer
              )
            }}
          </DropdownMenu>
        </:content>
      </DMenu>
    
  */
  {
    "id": "adUXKqED",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,1,[30,1,[\"id\"]]],[16,\"data-value\",[30,2]]],[[\"@onRegisterApi\",\"@triggerClass\",\"@disabled\",\"@placement\",\"@offset\",\"@modalForMobile\"],[[30,0,[\"registerMenuApi\"]],\"form-kit__control-menu\",[30,1,[\"disabled\"]],\"bottom-start\",5,true]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[1,[28,[32,1],[\"angle-down\"],null]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n          \"],[18,5,[[28,[32,3],null,[[\"Item\",\"Divider\",\"Container\"],[[50,[32,4],0,null,[[\"item\",\"field\",\"menuApi\"],[[30,4,[\"item\"]],[30,1],[30,0,[\"menuApi\"]]]]],[50,[32,5],0,null,[[\"divider\"],[[30,4,[\"divider\"]]]]],[32,6]]]]]],[1,\"\\n        \"]],[4]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@field\",\"@value\",\"@selection\",\"menu\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/menu.js",
    "scope": () => [DMenu, icon, DropdownMenu, hash, FKControlMenuItem, FKControlMenuDivider, FKControlMenuContainer],
    "isStrictMode": true
  }), this))();
}