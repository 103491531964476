import Component from "@glimmer/component";
import FKCharCounter from "discourse/form-kit/components/fk/char-counter";
import FKErrors from "discourse/form-kit/components/fk/errors";
import FKText from "discourse/form-kit/components/fk/text";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKMeta extends Component {
  get shouldRenderCharCounter() {
    return this.args.field.maxLength > 0 && !this.args.field.disabled;
  }
  get shouldRenderMeta() {
    return this.showMeta && (this.shouldRenderCharCounter || this.args.error || this.args.description?.length);
  }
  get showMeta() {
    return this.args.showMeta ?? true;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldRenderMeta}}
        <div class="form-kit__meta">
          {{#if @error}}
            <FKErrors @id={{@field.errorId}} @error={{@error}} />
          {{else if @description}}
            <FKText class="form-kit__meta-description">{{@description}}</FKText>
          {{/if}}
  
          {{#if this.shouldRenderCharCounter}}
            <FKCharCounter
              @value={{@value}}
              @minLength={{@field.minLength}}
              @maxLength={{@field.maxLength}}
            />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "1tQSz6HL",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRenderMeta\"]],[[[1,\"      \"],[10,0],[14,0,\"form-kit__meta\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[8,[32,0],null,[[\"@id\",\"@error\"],[[30,2,[\"errorId\"]],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"          \"],[8,[32,1],[[24,0,\"form-kit__meta-description\"]],null,[[\"default\"],[[[[1,[30,3]]],[]]]]],[1,\"\\n        \"]],[]],null]],[]]],[1,\"\\n\"],[41,[30,0,[\"shouldRenderCharCounter\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@value\",\"@minLength\",\"@maxLength\"],[[30,4],[30,2,[\"minLength\"]],[30,2,[\"maxLength\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@error\",\"@field\",\"@description\",\"@value\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/meta.js",
    "scope": () => [FKErrors, FKText, FKCharCounter],
    "isStrictMode": true
  }), this))();
}