import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import willDestroy from "@ember/render-modifiers/modifiers/will-destroy";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import BasicTopicList from "discourse/components/basic-topic-list";
import concatClass from "discourse/helpers/concat-class";
import i18n from "discourse-common/helpers/i18n";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
const LIST_ID = "related-Messages";
export default class RelatedMessages extends Component {
  static #_ = (() => dt7948.g(this.prototype, "moreTopicsPreferenceTracking", [service]))();
  #moreTopicsPreferenceTracking = (() => (dt7948.i(this, "moreTopicsPreferenceTracking"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  get hidden() {
    return this.moreTopicsPreferenceTracking.get("selectedTab") !== LIST_ID;
  }
  registerList() {
    this.moreTopicsPreferenceTracking.registerTopicList({
      name: I18n.t("related_messages.pill"),
      id: LIST_ID
    });
  }
  static #_3 = (() => dt7948.n(this.prototype, "registerList", [action]))();
  removeList() {
    this.moreTopicsPreferenceTracking.removeTopicList(LIST_ID);
  }
  static #_4 = (() => dt7948.n(this.prototype, "removeList", [action]))();
  get targetUser() {
    const topic1 = this.args.topic;
    if (!topic1 || !topic1.isPrivateMessage) {
      return;
    }
    const allowedUsers1 = topic1.details.allowed_users;
    if (topic1.relatedMessages?.length >= 5 && allowedUsers1.length === 2 && topic1.details.allowed_groups.length === 0 && allowedUsers1.find(u1 => u1.username === this.currentUser.username)) {
      return allowedUsers1.find(u1 => u1.username !== this.currentUser.username);
    }
  }
  static #_5 = (() => dt7948.n(this.prototype, "targetUser", [cached]))();
  get searchLink() {
    return getURL(`/search?expanded=true&q=%40${this.targetUser.username}%20in%3Apersonal-direct`);
  }
  static #_6 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div
        id="related-messages"
        class={{concatClass "more-topics__list" (if this.hidden "hidden")}}
        role="complementary"
        aria-labelledby="related-messages-title"
        {{didInsert this.registerList}}
        {{willDestroy this.removeList}}
      >
        <h3 id="related-messages-title" class="more-topics__list-title">
          {{i18n "related_messages.title"}}
        </h3>
  
        <div class="topics">
          <BasicTopicList
            @hideCategory="true"
            @showPosters="true"
            @topics={{@topic.relatedMessages}}
          />
        </div>
  
        {{#if this.targetUser}}
          <h3 class="see-all-pms-message">
            {{htmlSafe
              (i18n
                "related_messages.see_all"
                path=this.searchLink
                username=this.targetUser.username
              )
            }}
          </h3>
        {{/if}}
      </div>
    
  */
  {
    "id": "vBvqAxB4",
    "block": "[[[1,\"\\n    \"],[11,0],[24,1,\"related-messages\"],[16,0,[28,[32,0],[\"more-topics__list\",[52,[30,0,[\"hidden\"]],\"hidden\"]],null]],[24,\"role\",\"complementary\"],[24,\"aria-labelledby\",\"related-messages-title\"],[4,[32,1],[[30,0,[\"registerList\"]]],null],[4,[32,2],[[30,0,[\"removeList\"]]],null],[12],[1,\"\\n      \"],[10,\"h3\"],[14,1,\"related-messages-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"related_messages.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@hideCategory\",\"@showPosters\",\"@topics\"],[\"true\",\"true\",[30,1,[\"relatedMessages\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"targetUser\"]],[[[1,\"        \"],[10,\"h3\"],[14,0,\"see-all-pms-message\"],[12],[1,\"\\n          \"],[1,[28,[32,5],[[28,[32,3],[\"related_messages.see_all\"],[[\"path\",\"username\"],[[30,0,[\"searchLink\"]],[30,0,[\"targetUser\",\"username\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@topic\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/related-messages.js",
    "scope": () => [concatClass, didInsert, willDestroy, i18n, BasicTopicList, htmlSafe],
    "isStrictMode": true
  }), this))();
}