import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import DToggleSwitch from "discourse/components/d-toggle-switch";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlToggle extends Component {
  static controlType = "toggle";
  handleInput() {
    this.args.field.set(!this.args.value);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DToggleSwitch
        @state={{@value}}
        {{on "click" this.handleInput}}
        class="form-kit__control-toggle"
      />
    
  */
  {
    "id": "ElnqfMrq",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-toggle\"],[4,[32,1],[\"click\",[30,0,[\"handleInput\"]]],null]],[[\"@state\"],[[30,1]]],null],[1,\"\\n  \"]],[\"@value\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/toggle.js",
    "scope": () => [DToggleSwitch, on],
    "isStrictMode": true
  }), this))();
}