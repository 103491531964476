import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const SUPPORTED_TYPES = ["color", "date", "datetime-local", "email", "hidden", "month", "number", "password", "range", "search", "tel", "text", "time", "url", "week"];
export default class FKControlInput extends Component {
  static controlType = "input";
  constructor(owner1, args1) {
    super(...arguments);
    if (["checkbox", "radio"].includes(args1.type)) {
      throw new Error(`input component does not support @type="${args1.type}" as there is a dedicated component for this.`);
    }
    if (args1.type && !SUPPORTED_TYPES.includes(args1.type)) {
      throw new Error(`input component does not support @type="${args1.type}", must be one of ${SUPPORTED_TYPES.join(", ")}!`);
    }
  }
  get type() {
    return this.args.type ?? "text";
  }
  handleInput(event1) {
    const value1 = event1.target.value === "" ? undefined : this.type === "number" ? parseFloat(event1.target.value) : event1.target.value;
    this.args.field.set(value1);
  }
  static #_ = (() => dt7948.n(this.prototype, "handleInput", [action]))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="form-kit__control-input-wrapper">
        {{#if @before}}
          <span class="form-kit__before-input">{{@before}}</span>
        {{/if}}
  
        <input
          type={{this.type}}
          value={{@value}}
          class={{concatClass
            "form-kit__control-input"
            (if @before "has-prefix")
            (if @after "has-suffix")
          }}
          disabled={{@field.disabled}}
          ...attributes
          {{on "input" this.handleInput}}
        />
  
        {{#if @after}}
          <span class="form-kit__after-input">{{@after}}</span>
        {{/if}}
      </div>
    
  */
  {
    "id": "CYyemNkT",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"form-kit__control-input-wrapper\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,1],[14,0,\"form-kit__before-input\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[11,\"input\"],[16,4,[30,0,[\"type\"]]],[16,2,[30,2]],[16,0,[28,[32,0],[\"form-kit__control-input\",[52,[30,1],\"has-prefix\"],[52,[30,3],\"has-suffix\"]],null]],[16,\"disabled\",[30,4,[\"disabled\"]]],[17,5],[4,[32,1],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[14,0,\"form-kit__after-input\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@before\",\"@value\",\"@after\",\"@field\",\"&attrs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/input.js",
    "scope": () => [concatClass, on],
    "isStrictMode": true
  }), this))();
}