import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const PrivateMessageGlyph = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @shouldShow}}
    {{#if @href}}
      <a href={{@href}} title={{i18n @title}} aria-label={{i18n @ariaLabel}}>
        <span class="private-message-glyph-wrapper">
          {{icon "envelope" class="private-message-glyph"}}
        </span>
      </a>
    {{~else}}
      <span class="private-message-glyph-wrapper">
        {{icon "envelope" class="private-message-glyph"}}
      </span>
    {{~/if}}
  {{/if}}

*/
{
  "id": "fL5qGens",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[41,[30,2],[[[1,\"      \"],[10,3],[15,6,[30,2]],[15,\"title\",[28,[32,0],[[30,3]],null]],[15,\"aria-label\",[28,[32,0],[[30,4]],null]],[12],[1,\"\\n        \"],[10,1],[14,0,\"private-message-glyph-wrapper\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13]],[]],[[[1,\"      \"],[10,1],[14,0,\"private-message-glyph-wrapper\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]]],[1,\"\\n      \"],[13]],[]]]],[]],null]],[\"@shouldShow\",\"@href\",\"@title\",\"@ariaLabel\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/private-message-glyph.js",
  "scope": () => [i18n, icon],
  "isStrictMode": true
}), templateOnly());
export default PrivateMessageGlyph;