import Component from "@glimmer/component";
import { concat } from "@ember/helper";
import { action } from "@ember/object";
import UppyImageUploader from "discourse/components/uppy-image-uploader";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class FKControlImage extends Component {
  static controlType = "image";
  setImage(upload1) {
    this.args.field.set(upload1);
  }
  static #_ = (() => dt7948.n(this.prototype, "setImage", [action]))();
  removeImage() {
    this.setImage(undefined);
  }
  static #_2 = (() => dt7948.n(this.prototype, "removeImage", [action]))();
  static #_3 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <UppyImageUploader
        @id={{concat @field.id "-" @field.name}}
        @imageUrl={{readonly @value}}
        @onUploadDone={{this.setImage}}
        @onUploadDeleted={{this.removeImage}}
        class="form-kit__control-image no-repeat contain-image"
      />
    
  */
  {
    "id": "tHWE0xsu",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"form-kit__control-image no-repeat contain-image\"]],[[\"@id\",\"@imageUrl\",\"@onUploadDone\",\"@onUploadDeleted\"],[[28,[32,1],[[30,1,[\"id\"]],\"-\",[30,1,[\"name\"]]],null],[28,[31,0],[[30,2]],null],[30,0,[\"setImage\"]],[30,0,[\"removeImage\"]]]],null],[1,\"\\n  \"]],[\"@field\",\"@value\"],false,[\"readonly\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/form-kit/components/fk/control/image.js",
    "scope": () => [UppyImageUploader, concat],
    "isStrictMode": true
  }), this))();
}